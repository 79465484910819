import '../App.css';
import axios from "axios";
import { useNavigate} from "react-router-dom";
import "../components/components.css";
import logo from "../images/Logo_White@small.png";
import LoginErrors from  '../components/LoginErrors';

function Login(props) {
    const navigate = useNavigate();
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const handleSubmit = (event) => {
        event.preventDefault();
        props.clearLoginErrors();
        let username = document.forms[0].username.value;
        let password = document.forms[0].password.value;

        const headers =  {
          'content-type': 'multipart/form-data'
        }
        const credentials = { username: username, password: password};

        axios.post(process.env.REACT_APP_API_DOMAIN + '/api/login', credentials, {
          headers: headers
        })
        .then(response => {
          if(response.data.error !== undefined){
            props.handleLoginError("Invalid username / password combination");
            props.setIsLoggedIn(false);
            navigate('/'); 
          }
          let apiToken = response.data.success.api_token;
          sessionStorage.setItem("jwt", apiToken);
          props.setIsLoggedIn(true);
          navigate('/CampaignListPage');
        })
        .catch(function (error) {
          console.log("process.env.REACT_APP_API_DOMAIN:")
          console.log(process.env.REACT_APP_API_DOMAIN)
          console.log("Error Request:")
          console.log(error.request);
          console.log("Error Response:")
          console.log(error.response);
          console.log("Error Message:")
          console.log(error.message);
          props.handleLoginError("Contact system administrator");
          sessionStorage.setItem("jwt", "INVALID");
          props.setIsLoggedIn(false);
        });

    };

    return (
              <div style={{justifyContent: 'center', justifyItems: "center", textAlign: "center"}}>
                    <div className="mb-10 justify-center" style={{justifyContent: "center"}}>
                        <div className="flex items-center" style={{justifyContent: "center", backgroundColor: "black"}}>
                            <img src={logo} alt="Advertaze Logo"/>
                        </div>
                    </div>
                    <div className="flex items-center" style={{justifyContent: "center", }}>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-blue-900">Client Portal Login 
                          <span style={{color:"red"}}>
                          {
                            environment==="" || environment.toUpperCase()==="PRODUCTION"? "" : " - " + environment
                          }
                          </span>
                        </h2>
                    </div>
                    {
                      environment==="" || environment.toUpperCase()==="PRODUCTION"? "-" : ""
                    }
                    <div style={{justifyContent: "center", justifyItems: "center"}}>
                      <form className="mt-8 space-y-6"  onSubmit={handleSubmit}>
                        <div className="-space-y-px">
                          <div className='mb-3 pt-0'>
                            <LoginErrors error={props.loginError}/>
                            <label>Username </label>
                            <input type="text" className="w-96 px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full" name="username"  defaultValue="" required />
                          </div>

                        
                          <div className='mb-3 pt-0'>
                            <label>Password </label>
                            <input type="password" className="w-96 px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full" name="password" defaultValue="" required />
                          </div>

                          <div style={{ 
                                justifyContent: "center", 
                                justifyItems: "center", 
                                margin: "auto",
                                width:"5%",
                                paddingTop:"20px"}} 
                              className='mb-3  px-3 py-3 ' >
                              <button type="submit" className="create-campaign-button " style={{justifyContent: "center", justifyItems: "center"}} >
                              <div className="create-campaign-button-text">Submit</div>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
              </div>
    );
}
export default Login;