import React from "react";
import Dropdown from "./Dropdown";
import ApiService from "../Services/ApiService";
import RightArrow from "../images/right_arrow.png";
import "./components.css";
import {useNavigate} from "react-router-dom";

export default function CloneModal(props) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [defaultCloneDestination] = React.useState(props.nextTwoMonths[0].label);
  const [campaignList] = React.useState(props.campaignList);
  const  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [confirmationTitle, setModalTitle] = React.useState("");
  const [modalPrompt, setModalPrompt] = React.useState("");
  const [negativeButtonText, setNegativeButtonText] = React.useState("CANCEL");
  const [affirmativeButtonVisibility, setAffirmativeButtonVisibility] = React.useState("visible");

  function resetDialog(){
    setShowModal(false);
    setModalTitle("Clone campaign");
    setModalPrompt("");
    setNegativeButtonText("CANCEL");
    setAffirmativeButtonVisibility("visible");
  }
  function deletePreexistingCampaignsInTheDestinationMonth(clientId, campaignName){
    return new Promise((resolve, reject) => {
      let url = '/api/campaigns?client_id=' + encodeURI(clientId);
      ApiService.get(url)
        .then(async function (response) {
          for(let i=0; i< response.data.Campaigns.length; i++){
            if(response.data.Campaigns[i].campaign_name === campaignName){
                url = "/api/campaigns/delete?campaign_id=" + response.data.Campaigns[i].campaign_id;
                ApiService.post(url)
                  .then(async function (response) {
                    resolve("SUCCESS");
                  })
                  .catch(function (error) {
                    console.log("Failed to delete campaign_id: " + response.data.Campaigns[i].campaign_id);
                    console.log(error);
                    setTimeout(async function doSomething() {
                      resetDialog();
                    }, 2000);
                    reject("FAILURE");
                  })
            }
          }
          resolve("SUCCESS");
        })
        .catch(function (error) {
          console.log("CloneModal.js --> deletePreexistingCampaignsInTheDestinationMonth error" )    
          console.log(error);
          setModalTitle("Failure");
          setModalPrompt("Clone failed. " + error);
          setAffirmativeButtonVisibility("hidden");
          setNegativeButtonText("Close")
          setTimeout(async function doSomething() {
            resetDialog();
          }, 2000);
          reject("FAILURE");
        }) //try/catch
    }); //promise
  }//function

  function searchStringInArray (str, strArray) {
    for (var j=0; j<strArray.length; j++) {
        if (strArray[j].match(str)) return j;
    }
    return -1;
  }

  async function cloneCampaign(){
    let destinationCampaign = document.getElementById("cloneTo").value;
    let sourceCampaignMonth = document.getElementById("cloneFrom").value;
    const sourceCampaign = campaignList.find(campaign => campaign.month === sourceCampaignMonth);
    let campaignId = sourceCampaign.campaignId;
    let clientId;
    let clientName;
    //Get the source campaign:
    let url = '/api/campaigns/fetch?campaign_id=' + encodeURI(campaignId);
    ApiService.get(url)
        .then(async function (response) {
      let data = response.data.Campaign;
      let campaignName = data.client_name + "__" + destinationCampaign; 
      clientId = data.client_id;
      clientName = data.client_name;
      let campaignTotalBudget = data.campaign_total_budget;
      let conversionTrackings = data.conversion_trackings;
      let imageJson = data.image_json;
      let introTextA = data.introText_a;
      let introTextB = data.introText_b;
      let headlineA = data.headline_a;
      let headlineB = data.headline_b;
      let destinationUrlA = data.destinationUrl_a;
      let destinationUrlB = data.destinationUrl_b;
      let audienceNetwork = data.audience_network;
      let year = destinationCampaign.substring(destinationCampaign.length - 4);
      let monthName = destinationCampaign.substring(0, destinationCampaign.indexOf(' ')); 
      let monthNumber = searchStringInArray(monthName, months) + 1;
      let startDate = year + "-" + String(monthNumber).padStart(2, '0') + "-01";
      let endDate = year + "-" + String(monthNumber).padStart(2, '0') + "-28";

      //Delete any pre-existing campaigns in the target month:
      let result = await deletePreexistingCampaignsInTheDestinationMonth(clientId, campaignName);
      if (result === "FAILURE"){
        setModalTitle("Failure");
        setModalPrompt("Clone failed because historical campaigns in the same month exist and could not be deleted for some reason. ");
        setAffirmativeButtonVisibility("hidden");
        setNegativeButtonText("Close")
        setTimeout(async function doSomething() {
          resetDialog();
        }, 2000);
        return;
      }

      //Create the destination campaign:
      let url = "/api/campaigns/create" +
                `?client_id=${encodeURI(clientId)}&campaign_name=${encodeURI(campaignName)}&start_date=${encodeURI(startDate)}&end_date=${encodeURI(endDate)}&campaign_status_id=2`
      ApiService.post(url)
        .then(async function (response) {
          //Update the destination campaign:
          let data = response.data;
          let newCampaignId = data.ID;
          url = "/api/campaigns/update" +
          `?campaign_id=${encodeURI(newCampaignId)}&start_date=${encodeURI(startDate)}&campaign_name=${encodeURI(campaignName)}&end_date=${encodeURI(endDate)}&`+
          `audience_network=${encodeURI(audienceNetwork)}&campaign_total_budget=${encodeURI(campaignTotalBudget)}&`+
          `conversion_trackings=${encodeURI(conversionTrackings)}&imageJson=${encodeURI(imageJson)}&introText_a=${encodeURI(introTextA)}&` +
          `introText_b=${encodeURI(introTextB)}&headline_a=${encodeURI(headlineA)}&headline_b=${encodeURI(headlineB)}&destinationUrl_a=${encodeURI(destinationUrlA)}&`+
          `destinationUrl_b=${encodeURI(destinationUrlB)}`;

          ApiService.post(url)
          .then(async function (response) {
            let status;
            response.data.errors === undefined ? status = "SUCCESS" : status = "FAILURE";
            if(status==="SUCCESS"){
              ApiService.post("/api/polotno/generate-images", {'campaign_id': newCampaignId})
                .then(response => {
                  response.data.errors === undefined ? status = "SUCCESS" : status = "FAILURE";
                  if(status==="SUCCESS"){
                    setModalTitle("Success");
                    setModalPrompt("Clone successful.");
                    setAffirmativeButtonVisibility("hidden");
                    setNegativeButtonText("Close")
                    setTimeout(async function doSomething() {
                    resetDialog();
                    
                    props.handleClientChange(clientId, clientName);
                    }, 2000);
                  } else {  //Generate-images returned errors
                    console.log("CloneModal.js --> cloneCampaign() --generate-images returned errors")
                    console.log(response.data.errors);
                    setModalTitle("Failure");
                    setModalPrompt("Clone failed. " + response.data.errors);
                    setAffirmativeButtonVisibility("hidden");
                    setNegativeButtonText("Close")
                    setTimeout(async function doSomething() {
                    resetDialog();
                    }, 2000);
                  }

                })
                .catch(function (error) { //Generate-images failed
                  console.log("CloneModal.js --> cloneCampaign() --generate-images failed")
                  console.log(error);
                  setModalTitle("Failure");
                  setModalPrompt("Clone failed. " + error);
                  setAffirmativeButtonVisibility("hidden");
                  setNegativeButtonText("Close")
                  setTimeout(async function doSomething() {
                    resetDialog();
                  }, 2000);
                })
            } else { //update returned errors
              console.log(response.data.errors);
              setModalTitle("Failure");
              setModalPrompt("Clone failed. " + response.data.errors);
              setAffirmativeButtonVisibility("hidden");
              setNegativeButtonText("Close")
              setTimeout(async function doSomething() {
                resetDialog();
              }, 2000);
            }//if

          })
          .catch(function (error) { //update failed
              console.log(error);
              setModalTitle("Failure");
              setModalPrompt("Clone failed. " + error);
              setAffirmativeButtonVisibility("hidden");
              setNegativeButtonText("Close")
              setTimeout(async function doSomething() {
                resetDialog();
              }, 2000);
          })

      })
      .catch(function (error) { //create failed
          console.log(error);
          setModalTitle("Failure");
          setModalPrompt("Clone failed. " + error);
          setAffirmativeButtonVisibility("hidden");
          setNegativeButtonText("Close")
          setTimeout(async function doSomething() {
            resetDialog();
          }, 2000);
      })

    })
    .catch(function (error) { //Fetch of source campaign failed
        console.log("Your attempt to clone failed because your session expired.");
        props.handleLoginError("Your attempt to clone failed because your session expired.");
        navigate('/'); 
    })


    setShowModal(true);
  }

  return (
    <>
      <button
        className="clone-button"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Clone
      </button>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {confirmationTitle === "" ? "Clone Campaign" : "Success"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                { modalPrompt !== "" ? modalPrompt : 
                  <div className="relative p-6" >
                    <div style={{paddingLeft: "40px"}}>
                      <div style={{float: "left", width: "300px"}}>
                        <Dropdown 
                          id="cloneFrom"
                          labelTop="Clone From"
                          defaultValue={props.monthName}
                          options={props.campaignList}
                          handleChange={()=>{}}
                        />  
                      </div>

                      <div style={{float: "left", width: "40px", paddingLeft:"15px"}}>
                        <br/>
                        <br/>
                        <img src={RightArrow} alt="right arrow"/>
                      </div>

                      <div style={{float: "left", width: "250px"}}>
                        <Dropdown 
                          id="cloneTo"
                          labelTop="Clone To"
                          defaultValue={defaultCloneDestination}
                          options={props.nextTwoMonths}
                          handleChange={()=>{}}
                          /> 
                      </div>
                      
                      <br style={{clear: "left"}} />
                    </div>

                    <div> 
                      <p className="my-4 text-black text-sm text-left" style={{paddingLeft: "55px", width: "95%"}}>
                        Cloning a month will duplicate all campaigns and any variants in the from month.  You can remove variants and campaigns when editing.
                      </p>
                    </div>
                  </div>
                }
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    {negativeButtonText}
                    
                  </button>
                  
                  <button
                    className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => cloneCampaign()}
                    style={{visibility: affirmativeButtonVisibility}}
                  >
                    CLONE CAMPAIGN
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}