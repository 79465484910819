import * as React from 'react';
import "./components.css";
export default function Dropdown(props) {

  async function handleSelectChange(event) {
    let value = event.target.value;
    let selectedIndex = document.getElementById(props.id).selectedIndex;
    let text = document.getElementById(props.id).options[selectedIndex].text;
    let startDate = document.getElementById(props.id).options[selectedIndex].attributes.startdate;
    let endDate = document.getElementById(props.id).options[selectedIndex].attributes.enddate;
    let flightDates = document.getElementById(props.id).options[selectedIndex].attributes.flightdates;

    selectedIndex = document.getElementById("ClientList").selectedIndex;
    let clientId = document.getElementById("ClientList").options[selectedIndex].value;
    let clientNameText = document.getElementById("ClientList").options[selectedIndex].text;
    let openParenthesisLocation = clientNameText.indexOf(" (");
    let clientName = clientNameText.substr(0, openParenthesisLocation);

    props.handleChange(value, text, startDate !== undefined? startDate.value : null, endDate !== undefined? endDate.value : null, flightDates !== undefined? flightDates : null, clientId, clientName);
  }
  
  return(
    <>
        <div className="form-group form-inline">
          <label 
            className="select-top-label" 
            style={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "150%"
            }}
          >{props.labelTop}</label>
        </div>
        <div className="form-group form-inline">
          <label 
          className="select-left-label"
           style={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "150%"
          }}
          >{props.labelLeft}</label>
          <select 
            id={props.id}
            value={
                props.defaultValue === -1 ?
                 "SELECT A VALUE..."
                  :
                  props.defaultValue
            }
            className="form-control" 
            style={{ 
              width: props.width,
              paddingLeft:"5px",
              height: "40px",
              border: "1px solid #D4D4D8",
              borderRadius: "4px",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              color: "#71717A"
            }}
            onChange={
              handleSelectChange
            }    
          >

              <option key="-1" disabled value="SELECT A VALUE..." >
                [SELECT A VALUE...]
              </option>
                {
                  props.options.map((values, index) =>
                      <option 
                        key={values.value} 
                        value={String(values.value)} 
                        startdate={values.startDate !== undefined? values.startDate: null} 
                        enddate={values.endDate !== undefined? values.endDate: null}
                        flightdates={values.flightdates !== undefined? values.flightdates: null}
                      >
                        {values.label} {values.value.length === 0 || parseInt(values.value) < 0 ?  "(Not Saved)" : "(" + values.value + ")"}
                      </option>
                  )
                }
          </select>
        </div>
        </>
  );
}