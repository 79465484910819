import Variant from "./Variant";
import Textbox from '../components/Textbox';
import ChangeConfirmationModal from '../components/ChangeConfirmationModal';
import './components.css';
import "../pages/styles.css";
import React, { useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "./TabSelector";
import ClientList from "./ClientList";
import CampaignList from "./CampaignList";
import { DesignEditor } from './design-editor';
import ApiService from "../Services/ApiService";
import _debounce from 'lodash/debounce';
import {useNavigate} from "react-router-dom";
import progress from "../images/progress.gif";
import CsvTemplateDownloadLink from "./CsvTemplateDownloadLink";
import logo from "../images/logo.jfif";
import CsvFileUploadResults from "./CsvFileUploadResults.js";
import DragAndDropFileUpload from "./DragAndDropFileUpload.js";
import ImageUploadInProgressModal from "./ImageUploadInProgressModal.js";
import UploadFormattingInstructions from "./UploadFormattingInstructions.js";
import emailjs from '@emailjs/browser';

export default function TabPages(props) {
    const navigate = useNavigate();
    const [localSelectedCampaignId, setLocalSelectedCampaignId] = useState("");
    const [introTextA, setIntroTextA] = useState("");
    const [destinationUrlA, setDestinationUrlA] = useState("");
    const [headlineA, setHeadlineA] = useState("");
    const [introTextB, setIntroTextB] = useState("");
    const [destinationUrlB, setDestinationUrlB] = useState("");
    const [headlineB, setHeadlineB] = useState("");
    const [campaignTotalBudget, setCampaignTotalBudget] = useState("");
    const [conversionTrackings, setConversionTrackings] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [companyNameList, setCompanyNameList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [campaignTotalBudgetCheckMarkState, setCampaignTotalBudgetCheckMarkState] = useState("hide");
    const [conversionTrackingsCheckMarkState, setConversionTrackingsCheckMarkState] = useState("hide"); 
    const [introTextACheckMarkState, setIntroTextACheckMarkState] = useState("hide"); 
    const [headlineACheckMarkState, setHeadlineACheckMarkState] = useState("hide"); 
    const [destinationUrlACheckMarkState, setDestinationUrlACheckMarkState] = useState("hide"); 
    const [introTextBCheckMarkState, setIntroTextBCheckMarkState] = useState("hide"); 
    const [headlineBCheckMarkState, setHeadlineBCheckMarkState] = useState("hide"); 
    const [destinationUrlBCheckMarkState, setDestinationUrlBCheckMarkState] = useState("hide"); 
    const [campaignTotalBudgetCount, setCampaignTotalBudgetCount] = useState(0);
    const [conversionTrackingsCount, setConversionTrackingsCount] = useState(0); 
    const [introTextACount, setIntroTextACount] = useState(0); 
    const [headlineACount, setHeadlineACount] = useState(0); 
    const [destinationUrlACount, setDestinationUrlACount] = useState(0); 
    const [introTextBCount, setIntroTextBCount] = useState(0); 
    const [headlineBCount, setHeadlineBCount] = useState(0); 
    const [destinationUrlBCount, setDestinationUrlBCount] = useState(0); 
    const [accountUploadSuccess, setAccountUploadSuccess] = useState([]);
    const [accountUploadSkipped, setAccountUploadSkipped] = useState([]);
    const [accountUploadFail, setAccountUploadFail] = useState([]);
    const [displayAccountUploadResults, setDisplayAccountUploadResults] = useState(false);
    const [uploadedCSVFile, setUploadedCSVFile] = useState(""); 
    const [displayImageUploadPopup, setDisplayImageUploadPopup] = useState(false);

    const [imageDataDirtyFlagA, setImageDataDirtyFlagA] = useState(false);
    const [imageDataDirtyFlagB, setImageDataDirtyFlagB] = useState(false);
    const [introTextACursorPosition, setIntroTextACursorPosition] = useState(0);
    const [introTextBCursorPosition, setIntroTextBCursorPosition] = useState(0);
    const [headlineACursorPosition, setHeadlineACursorPosition] = useState(0);
    const [headlineBCursorPosition, setHeadlineBCursorPosition] = useState(0);
    const [destinationUrlACursorPosition, setDestinationUrlACursorPosition ] = useState(0);
    const [destinationUrlBCursorPosition, setDestinationUrlBCursorPosition ] = useState(0);
    let selectedTab = "";
    let backgroundImage = "";



    React.useEffect(() => {
        resetAllFormFields();

        //If this is a saved campagin, pull back and display the campaign data:
        if (props.selectedCampaignId > 0){
            fetchLinkedInCompaniesData();  
            fetchCampaignData();
        } else {
            handleSave();
        }
    }, [props.selectedCampaignId, props.selectedClientId]);

    React.useEffect(() => {
        if ((imageDataDirtyFlagA) && (props.imageJsonA !== null)){  //If the imageJson changes after it is initially populated
            if (props.selectedCampaignId > 0){ //Update this campaign if it exists; create it if it doesn't.
                updateCampaign(props.selectedCampaignId, 2);
            } else {
                createCampaign(updateCampaign, null, 2);
            }
            setImageDataDirtyFlagA(false);
        }  

        if ((imageDataDirtyFlagB) && (props.imageJsonB !== null)){  //If the imageJson changes after it is initially populated
            if (props.selectedCampaignId > 0){ //Update this campaign if it exists; create it if it doesn't.
                updateCampaign(props.selectedCampaignId, 2);
            } else {
                createCampaign(updateCampaign, null, 2);
            }
            setImageDataDirtyFlagB(false);
        }  
    }, [props.imageJsonA, props.imageJsonB, props.backgroundImageA, props.backgroundImageB]);


/**
 * Form Field Functions:
 */
    let [variants] = useState({ "Variant A": [], "Variant B": [], })

    function handleChangeIntroTextA(introTextA){
        setIntroTextACount(introTextA.length)
        setIntroTextA(introTextA);
    }
    function handleChangeIntroTextB(introTextB){
        setIntroTextBCount(introTextB.length)
        setIntroTextB(introTextB);
    }
    function handleChangeHeadlineA(headlineA){
        setHeadlineACount(headlineA.length)
        setHeadlineA(headlineA);
    }
    function handleChangeHeadlineB(headlineB){
        setHeadlineBCount(headlineB.length)
        setHeadlineB(headlineB);
    }
    function handleChangeDestinationUrlA(destinationUrlA){
        setDestinationUrlACount(destinationUrlA.length)
        setDestinationUrlA(destinationUrlA);
    }
    function handleChangeDestinationUrlB(destinationUrlB){
        setDestinationUrlBCount(destinationUrlB.length)
        setDestinationUrlB(destinationUrlB);
    }
    function handleChangeCampaignTotalBudget(campaignTotalBudget){
        setCampaignTotalBudgetCount(campaignTotalBudget.length)
        setCampaignTotalBudget(campaignTotalBudget);
    }
    function handleChangeConversionTrackings(conversionTrackings){
        setConversionTrackingsCount(conversionTrackings.length);
        setConversionTrackings(conversionTrackings);
    }

    async function toggleCheckMark(id, actualCharacterCount, requiredCharacterCount){
        let className = "hide";
        if (actualCharacterCount >= requiredCharacterCount) {
            className = "";
        }

        switch (id){
            case "campaignTotalBudget":
                await setCampaignTotalBudgetCheckMarkState(className);
                break;
            case "conversionTrackings":
                await setConversionTrackingsCheckMarkState(className);
                break;
            case "introTextA":
                await setIntroTextACheckMarkState(className);
                break;
            case "headlineA":
                await setHeadlineACheckMarkState(className);
                break;
            case "destinationUrlA":
                await setDestinationUrlACheckMarkState(className);
                break;
            case "introTextB":
                await setIntroTextBCheckMarkState(className);
                break;
            case "headlineB":
                await setHeadlineBCheckMarkState(className);
                break;
            case "destinationUrlB":
                await setDestinationUrlBCheckMarkState(className);
                break;
            default:
                break;
        }
    }//function



/**
 * Image Editor Functions:
 */

    var handleEditorChange = _debounce((json) => {
        selectedTab = document.getElementById("selectedTab").value;
        if((json !== props.imageJsonA) && (props.imageJsonA !== undefined) && (json !== undefined) && (selectedTab === "A")){  //Determine if user updated the image editor
            setImageDataDirtyFlagA(true);
            props.setImageJsonA(json);
        }//if
        if((json !== props.imageJsonB) && (props.imageJsonB !== undefined) && (json !== undefined) && (selectedTab === "B")){  //Determine if user updated the image editor
            setImageDataDirtyFlagB(true);
            props.setImageJsonB(json);
        }//if
    }, 200);

    function getBackgroundImage (){
        selectedTab = document.getElementById("selectedTab").value;
        if (selectedTab === "A"){
            return props.backgroundImageA
        } else {
            return props.backgroundImageB
        }
    }


/**
* Progress Indicator Functions:
*/
    function toggleProgressIndicatorOn(){
        let el = document.getElementById("progress-indicator");
        el.style.visibility = "visible";
    }

    function toggleProgressIndicatorOff(){
        let el = document.getElementById("progress-indicator");
        el.style.visibility = "hidden";
    }

/**
 * Campaign Population Functions:
 */
     function fetchLinkedInCompaniesData(){
        const url = '/api/campaigns/linkedin-companies?campaign_id='+ props.selectedCampaignId;
        ApiService.get(url)
            .then(async function doResponse(response) {            
            let data = response.data;
            let companies = data.Companies;
            if (data.error) {
                console.log("Your session has expired.")
                props.handleLoginError("Your session has expired.");
                navigate('/'); 
            }
            var fieldsArray = [];
            var fieldsArray2 = [];

            for(let i=0; i< companies.length; i++){
                fieldsArray.push({
                    id: i,
                    campaign_id: companies[i].campaign_id,
                    common_name: companies[i].common_name,
                    linkedin_company_id:companies[i].linkedin_company_id
                });
                fieldsArray2.push(companies[i].common_name);
            }//for

            await setCompaniesList(fieldsArray.sort(sortByCommonName));
            await setCompanyNameList(fieldsArray2.sort(sortByCommonName));
        })
        .catch(function (error) {
            console.log("An error occured:");
            console.log(error);
        })
    }
    async function resetAllFormFields(){
        await setIntroTextA("");
        await setDestinationUrlA("");
        await setHeadlineA("");
        await setIntroTextB("");
        await setDestinationUrlB("");
        await setHeadlineB("");
        await props.setImageJsonA(null);
        await props.setImageJsonB(null);
        await setCampaignTotalBudget("");
        await setConversionTrackings([])
        await setCompaniesList();
        await setCompanyNameList();
        await setCampaignTotalBudgetCheckMarkState("hide");
        await setConversionTrackingsCheckMarkState("hide");
        await setIntroTextACheckMarkState("hide");
        await setHeadlineACheckMarkState("hide");
        await setDestinationUrlACheckMarkState("hide");
        await setIntroTextBCheckMarkState("hide");
        await setHeadlineBCheckMarkState("hide");
        await setDestinationUrlBCheckMarkState("hide");
        await setCampaignTotalBudgetCount(0);
        await setConversionTrackingsCount(0); 
        await setIntroTextACount(0); 
        await setHeadlineACount(0); 
        await setDestinationUrlACount(0); 
        await setIntroTextBCount(0); 
        await setHeadlineBCount(0); 
        await setDestinationUrlBCount(0); 
        await setSelectedCompany("");
        await props.setBackgroundImageA("");
        await props.setBackgroundImageB("");

    }
    async function fetchCampaignData(){
        const url = '/api/campaigns/fetch?campaign_id='+ props.selectedCampaignId ;
        ApiService.get(url)
        .then(async function (response) {
            let data = response.data.Campaign;

            setIntroTextA(data.introText_a);
            setIntroTextACount(data.introText_a.length)
            setDestinationUrlA(data.destinationUrl_a);
            setDestinationUrlACount(data.destinationUrl_a.length);
            setHeadlineA(data.headline_a);
            setHeadlineACount(data.headline_a.length);
            setIntroTextB(data.introText_b);
            setIntroTextBCount(data.introText_b.length);
            setDestinationUrlB(data.destinationUrl_b);
            setDestinationUrlBCount(data.destinationUrl_b.length);
            setHeadlineB(data.headline_b);
            setHeadlineBCount(data.headline_b.length);
            props.setImageJsonA(data.image_json_a);
            props.setImageJsonB(data.image_json_b);
            setCampaignTotalBudget(data.campaign_total_budget);
            setCampaignTotalBudgetCount(data.campaign_total_budget.length);
            if(data.conversion_trackings.length > 0){
                setConversionTrackings(data.conversion_trackings[0]);
                setConversionTrackingsCount(data.conversion_trackings[0].length);
            }

            try {
                let backgroundA = data.image_json_a.pages[0].children[0].src;
                props.setBackgroundImageA(backgroundA);
            } catch (e){
            }
            try {
                let backgroundB = data.image_json_b.pages[0].children[0].src;
                props.setBackgroundImageB(backgroundB);
            } catch (e){
            }

        })
        .catch(function (error) {
            console.log("TabPages.js --> fetchCampaignData  ERROR:")
            console.log(error);
        })
    }

/**
 *  Campaign Maintenance Functions: * 
 */
    function publishCampaignImageToCompanies(lcampaignId){
        console.log("publishCampaignToCompanies -- START")
        return new Promise(function(resolve, reject) {   
            ApiService.post("/api/polotno/generate-images", {'campaign_id': lcampaignId})
            .then(response => {
                toggleProgressIndicatorOff();
                console.log("publishCampaignToCompanies -- END")
                resolve("SUCCESS");
            })
            .catch(function (error) { //Generate-images failed
                toggleProgressIndicatorOff();
                console.log("publishCampaignToCompanies -- ERROR: " + error)
                reject(error);
            })//try
        });
    }

    function updateCampaign(lcampaignId, campaignStatusId){
        let url ="";
        return new Promise(function(resolve, reject) { 

            url = "/api/campaigns/update";
            let jsonData = {
                "campaign_id": lcampaignId,
                "campaign_name": props.selectedClientName + "__" + props.selectedMonth,
                "ad_number":  (props.selectedTab === "A" ? 1 :  2) ,
                "start_date": props.startDate,
                "end_date": props.endDate,
                "introText_a": introTextA,
                "destinationUrl_a": destinationUrlA,
                "headline_a": headlineA,
                "introText_b": introTextB,
                "destinationUrl_b": destinationUrlB,
                "headline_b": headlineB,
                "image_json_a": JSON.stringify(props.imageJsonA),
                "image_json_b": JSON.stringify(props.imageJsonB),
                "campaign_total_budget": campaignTotalBudget,
                "conversion_trackings": conversionTrackings,
                "campaign_status_id":  campaignStatusId
            };


            ApiService.post(url, jsonData)
            .then(async function (response) {
                props.updateSelectedCampaignId(lcampaignId);
                resolve("SUCCESS")
            })
            .catch(async function (error) {
                console.log("Tabpages.js --> updateCampaign: An error occurred in updating the campaign: " + lcampaignId);
                console.log("url was:")
                console.log(url);
                console.log(error);
                reject(error);
            })
        });
    }

    async function createCampaign(fn, file, campaignStatusId){

        return new Promise(function(resolve, reject) { 
            let url = '/api/campaigns/create?client_id=' + encodeURI(props.selectedClientId) + 
                "&campaign_name=" + encodeURI(props.selectedClientName + "__" + props.selectedMonth) + 
                "&start_date=" + encodeURI(props.startDate) + 
                "&end_date=" + encodeURI(props.endDate) +
                "&campaign_status_id=" + campaignStatusId;

                ApiService.post(url)
                .then(async function (response) {
                    await fn(response.data.ID, 2);
                    setLocalSelectedCampaignId(response.data.ID);
                    props.updateSelectedCampaignId(response.data.ID);
                    props.updateCampaignListOptions(props.selectedClientId, props.selectedClientName);
                    resolve("SUCCESS");
                }).catch(async function (error) {
                    console.log(error);
                    reject(error)
                });
            
        });
    }

    /**
     * 
     * Auto send an email after user submits campaign.
     */

    function sendEmailAfterSubmit() {
        console.log("TabPages.js --> sendEmailAfterSubmit " + props.selectedClientName + " -- START")
        let service = process.env.REACT_APP_EMAILJS_SERVICE;
        let template = process.env.REACT_APP_EMAILJS_TEMPLATE_FOR_CAMPAIGN_SUBMIT;
        let publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
        let replyTo = process.env.REACT_APP_EMAILJS_REPLY_TO;
        let fromName = process.env.REACT_APP_EMAILJS_FORM_NAME;

        var templateParams = {
            from_name: fromName,
            reply_to: replyTo,
            client_name: props.selectedClientName
        };

        emailjs.send(service, template, templateParams, publicKey)
            .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
            }, function(error) {
            console.log('FAILED...', error);
            });
            console.log("TabPages.js --> sendEmailAfterSubmit " + props.selectedClientName + " -- END")
    } //sendEmailAfterSubmit()

    async function saveOrSubmit(campaignStatusId){
      return new Promise(async (resolve, reject) => {
        if((parseInt(props.selectedCampaignId) < 0 )||(parseInt(props.selectedCampaignId) === undefined)){
            let result = await createCampaign(updateCampaign, null, campaignStatusId);
            resolve(result);
        } else {
            let result = await updateCampaign(props.selectedCampaignId, campaignStatusId);
            resolve(result);
        }//if
       });
    }//saveOrSubmit

    async function handleSave(){
        return  new Promise(async function doSomething(resolve, reject)  {
	        toggleProgressIndicatorOn(); 
            let result =  await saveOrSubmit(2);
            toggleProgressIndicatorOff(); 
            resolve("SUCCESS");

        });
    }

    function handleSubmit(){
        return  new Promise(async function doSomething(resolve, reject)  {
	    toggleProgressIndicatorOn(); 
            let result =  await saveOrSubmit(1);
           
            if(result === "SUCCESS") {
                sendEmailAfterSubmit() ;
                result = await publishCampaignImageToCompanies(props.selectedCampaignId);
                resolve(result);
            } else {
                reject(result);
            }
            toggleProgressIndicatorOff(); 
        });
    } //handleSubmit

    async function handleDelete(){
	    toggleProgressIndicatorOn(); 
        const url = '/api/campaigns/delete?campaign_id=' + props.selectedCampaignId;
        return  new Promise(async function doSomething(resolve, reject)  {
            ApiService.post(url)
                .then(response => {
                props.handleClientChange(props.selectedClientId, props.selectedClientName);
	            toggleProgressIndicatorOff(); 
                navigate(-1);
                resolve(1);
            })
            .catch(function (error) {
                console.log("An error occured:");
                console.log(error);
                resolve(-1);
            })
        });
    }//handleDelete



/**
 * Account List Functions:
 */
    function handleCompanyNameChange(props) {
        let id = props.target.id;
        let element = document.getElementById(id);
        let attributes = element.attributes;
        let campaignId = attributes.campaignid.value;
        let linkedinCompanyId = attributes.linkedincompanyid.value;
        let commonName = element.value;
        setSelectedCompany(commonName);

        const url = '/api/campaigns/update-linkedin-company?campaign_id='+ campaignId + "&linkedin_company_id=" + linkedinCompanyId + "&common_name=" + commonName + "&default_name=" + commonName;
        ApiService.get(url)
            .then(response => {
        })
        .catch(function (error) {
            console.log("An error occured:");
            console.log(error);
        })
    }
 
    function sortByCommonName(a,b){
        var keyA = a.common_name;
        var keyB = b.common_name;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
    }



/**
 * Campaign Image Upload Functions:
 */
    function uploadImageToCampaign(lcampaignId, file){

        props.updateSelectedCampaignId(lcampaignId);
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        data.append('image', file);
        data.append('campaign_id', parseInt(lcampaignId));
        let adNumber = 1;
        if (props.selectedTab === "B") {
            adNumber = 2;
        } 
        data.append('ad_number', adNumber)
        let url = process.env.REACT_APP_API_DOMAIN + "/api/campaigns/upload-image";
        const token = sessionStorage.getItem('jwt');
        var config = {
          method: 'post',
          url: url,
          headers: { 
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
          },
          data : data
        };
   
        axios(config)
        .then(async function (response) {
            let url = response.data.URL;

            if((props.selectedTab === "A") || (props.selectedTab === undefined )){
                await props.setBackgroundImageA(url);
            } else {
                await props.setBackgroundImageB(url);
            }
            
            updateCampaign(lcampaignId, -1)
        })
        .catch(function (error) {
            console.log("TabPages --> uploadImageToCampaign  error:");
            console.log(error);
        });
    }

    const handleImageFileUpload = async (file) => {
        setDisplayImageUploadPopup(true);
        setTimeout(() => {
              setDisplayImageUploadPopup(false);
            }, 2500);
          
        if (props.selectedCampaignId < 0){  //If this is an un saved campaign, we have to save it to get it a campaignId before we can upload an image.
            createCampaign(uploadImageToCampaign, file, 2)
        } else {
            uploadImageToCampaign(props.selectedCampaignId, file)
        }          
        toggleProgressIndicatorOff();    
    };
    
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
      

    /**
     * Account List Upload Functions:
     */
    const handleAccountFileUpload = async (file) => {
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        data.append('file', file);   
        const token = sessionStorage.getItem('jwt');
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/api/campaigns/client-import-accounts?campaign_id=' + props.selectedCampaignId,
            headers: { 
                'Authorization': `Bearer ${token}`
            },
            data : data
            };
    
        axios(config)
        .then(async function (response) {
            setAccountUploadSuccess(response.data.Success);
            setAccountUploadSkipped(response.data.Skipped);
            setAccountUploadFail(response.data.Fail);
            toggleProgressIndicatorOff();  
            fetchLinkedInCompaniesData();
            setUploadedCSVFile(file.name);

            if (response.data.Success) {
                setDisplayAccountUploadResults(true);
            } else {
                document.getElementById("label-file-upload").innerHTML = "<div style='color:red'>" + response.data.Message+ "</div>";
                const myTimeout = setTimeout(resetCSVAccountUploadFileDrop, 5000);

                function resetCSVAccountUploadFileDrop() {
                    document.getElementById("label-file-upload").innerHTML  = "Click here or drop CSV file here"
                }

            }//if
        })
        .catch(function (error) {
            console.log("error:")
            console.log(error);
        });

      };


    function toggleShowModal(value){
        setDisplayAccountUploadResults(value);
        if (value === false ){
            setAccountUploadSuccess([]);
            setAccountUploadSkipped([]);
            setAccountUploadFail([]);
        }
    }

    function wipeSelectedCompany(){
        setSelectedCompany("");
    }


  return (
<>
    {/* Client Area Boundary */}
    <div> 

        {/*  Top Left Corner: Build Your Ad & Progress Circle */}
        <div className="div-campaign-screen-title">Build Your Ad
            
            <CsvFileUploadResults 
                accountUploadSuccess={accountUploadSuccess}
                accountUploadSkipped={accountUploadSkipped}
                accountUploadFail={accountUploadFail}
                showModal={displayAccountUploadResults}
                toggleShowModal={toggleShowModal}
                uploadedCSVFile={uploadedCSVFile}
                toggleProgressIndicatorOff={toggleProgressIndicatorOff}
            />
            <div className="progress-circle" style={{postion: "relative", left: "90px", height: "50px"}}>
                    <img 
                        id="progress-indicator" 
                        style={{visibility: "hidden"}} 
                        className="progress-circle" 
                        src={progress} 
                        alt="progress"/>
            </div>
        </div>
        {/*  Top Left Corner: Build Your Ad & Progress Circle */}

        <div className="div-client-name"> 
            <ClientList
                    selectedClientName={props.selectedClientName}
                    selectedClientId={props.selectedClientId}
                    handleChange={props.handleClientChange}
                />
        </div>
        <div className="div-campaign-date">
            <CampaignList 
                refreshCampaignList={true}
                getCampaignListOptions={props.getCampaignListOptions}
                campaignListOptions={props.campaignListOptions}
                selectedClientId={props.selectedClientId}
                selectedClientName={props.selectedClientName}
                selectedCampaignId={props.selectedCampaignId}
                selectedCampaignName={props.selectedCampaignName}
                selectedMonth={props.selectedMonth}
                startDate={props.startDate}
                endDate={props.endDate}
                flightDates={props.flightDates}
                handleChange={props.handleCampaignChange}
            />
        
        </div>


        <br/><br/><br/>


        {/* Budget, Conversion, Upload Account List, and Background Image Columns */}
        <div className="campaign-header" id="about" style={{zindex:"1", position:"relative"}}>

            {/* Budget this Month */}
            <div className="campaign-header-box campaign-header-first-box">
                <Textbox 
                    id={'campaignTotalBudget'}  
                    label={'Budget this month'} 
                    placeholderText={'$'} 
                    maxCharacters={7} 
                    minCharacters={1} 
                    value={campaignTotalBudget}
                    isNumeric={true}
                    insertCompanyNameButtonFlag={false}
                    handleChange={handleChangeCampaignTotalBudget}
                    checkMarkState={campaignTotalBudgetCheckMarkState}
                    toggleCheckMark={toggleCheckMark}
                    characterCount={campaignTotalBudgetCount}
                />    
            </div>
            {/* Budget this Month */}

            {/* Conversion tracking */}
            <div className="campaign-header-box">
                <Textbox 
                    id={'conversionTrackings'}  
                    label={'Conversion tracking'} 
                    placeholderText={'Optional'} 
                    maxCharacters={255} 
                    minCharacters={0} 
                    value={conversionTrackings}
                    insertCompanyNameButtonFlag={false}
                    handleChange={handleChangeConversionTrackings}
                    checkMarkState={conversionTrackingsCheckMarkState}
                    toggleCheckMark={toggleCheckMark}
                    characterCount={conversionTrackingsCount}
                />
                <div style={{position:"relative", top: "-15px"}}>(Comma-separated values)</div>
            </div>
            {/* Conversion tracking */}

            {/* Upload Account List */}
            <div className="campaign-header-box" >
                <div style={{position:"relative", top: "-15px"}}>
                    <div className="label-campaign-form-field" >
                        Upload account list
                    </div><div>
                        <UploadFormattingInstructions/>
                    </div>
                </div>
                <div style={{position:"relative", top: "-18px"}}>
                    <CsvTemplateDownloadLink/>
                    <DragAndDropFileUpload
                        id="accountFileUpload" 
                        onChange={handleAccountFileUpload}
                        dragAndDropMessage="Click here or drop CSV file here"
                        permissibleFileTypes={[".csv", ".txt"]}
                        toggleProgressIndicator={toggleProgressIndicatorOn}
                        width="235px"
                    />
                    <br/>
                </div>
            </div>
            {/* Upload Account List */}

            {/* Upload Background Image */}
            <div className="campaign-header-box" style={{position: "relative", left: "-13px"}}>
                <div>
                    <div className="label-campaign-form-field" >
                        Background Image
                    </div>
                </div>
                <div>
                    <DragAndDropFileUpload 
                        id="imageFileUpload"
                        onChange={handleImageFileUpload}
                        dragAndDropMessage="Click here or drop file here"
                        permissibleFileTypes={[".jpeg", ".png", ".jpg"]}
                        toggleProgressIndicator={toggleProgressIndicatorOn}
                        width="235px"
                    />
                </div>
            </div>
            {/* Upload Background Image */}

        </div>    
        {/* Budget, Conversion, Uupload Account List, and Background Image Columns */}  
       

        {/* Tab Pages: Variant A and Variant B*/}
        <div className="w-full max-w-xs" style={{position: "relative", top: "-30px"}}>

            {/* Tab Page Selectors */}
            <nav className="flex border-b border-gray-300">
                <TabSelector
                    isActive={props.selectedTab === "A"}
                    onClick={() => {
                        props.setSelectedTab("A");
                    }}
                >
                Variant A
                </TabSelector>
                <TabSelector
                    isActive={props.selectedTab === "B"}
                    onClick={() => {
                        props.setSelectedTab("B")
                        }
                    }
                >
                Variant B
                </TabSelector>
            </nav>
            {/* Tab Page Selectors */}


            {/* Tab Page Contents */}
            <div className="p-4">

                {/* Tab Page: Variant A  -- Includes Ad Preview but NOT Design Editor*/}
                <TabPanel hidden={props.selectedTab !== "A"}>
                    <br/>
                    <Variant 
                        key={1}
                        campaignId={props.selectedCampaignId}
                        introText={introTextA}
                        destinationUrl={destinationUrlA}
                        headline={headlineA}
                        variant="A"
                        introTextCursorPosition = {introTextACursorPosition}
                        setIntroTextCursorPosition = {setIntroTextACursorPosition}
                        headlineCursorPosition = {headlineACursorPosition}
                        setHeadlineCursorPosition = {setHeadlineACursorPosition}
                        destinationUrlCursorPosition = {destinationUrlACursorPosition}
                        setDestinationUrlCursorPosition = {setDestinationUrlACursorPosition}
                        handleChangeIntroText={handleChangeIntroTextA}
                        handleChangeHeadline={handleChangeHeadlineA}
                        handleChangeDestinationUrl={handleChangeDestinationUrlA}
                        handleSave={handleSave}
                        introTextCheckMarkState={introTextACheckMarkState}
                        headlineCheckMarkState={headlineACheckMarkState}
                        destinationUrlCheckMarkState={destinationUrlACheckMarkState}
                        toggleCheckMark={toggleCheckMark}
                        introTextCount={introTextACount}
                        headlineCount={headlineACount}
                        destinationUrlCount={destinationUrlACount}
                    />
                    
                    <div style={{
                        position: "absolute", 
                        left: "350px", 
                        top: "10px", 
                        height: '440px', 
                        width: "105vh"
                        }} >
                        <h4>Ad Preview</h4>
                        <div 
                            style={{
                                border: "solid 1px  #DDDDDD", 
                                background: "#F9F9F9",
                            }}
                            >
                            <div style={{height: "5px"}}></div>
                            <div >
                                <div 
                                    style={{
                                            position: "relative", 
                                            left: "10px", 
                                            width: "90%", 
                                            height: "80px"
                                        }}
                                    >
                                    <img alt="logo" src={logo} style={{width: "50px"}} />
                                    <div 
                                        style={{
                                                position: "relative", 
                                                top: "-50px", 
                                                left: "55px"
                                            }}>
                                        <span style={{fontWeight: "bold"}}>Advertaze</span><br/>
                                        <div style={{color: "#999999"}}>38 Followers<br/>
                                        Promoted</div>
                                    </div>
                                    <div 
                                        style={{
                                            paddingTop: "5px",
                                            position: "relative", 
                                            top: "-50px"
                                        }}
                                    >
                                        <input  disabled
                                            style={{
                                                fontWeight: "bold", 
                                                fontFamily: 'Roboto', 
                                                fontSize: "16px", 
                                                backgroundColor: "rgb(249, 249, 249)",
                                                width: "100%"}} 
                                            type="text" 
                                            value={
                                            (selectedCompany !== "") ? introTextA.replaceAll("{COMPANY_NAME}", selectedCompany) : introTextA
                                        } 
                                            onChange={console.log}
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>


                    

                </TabPanel>
                {/* Tab Page: Variant A  -- Includes Ad Preview but NOT Design Editor*/}


                {/* Tab Page: Variant B  -- Includes Ad Preview but NOT Design Editor*/}
                <TabPanel hidden={props.selectedTab !== "B"}>
                        <br/>
                    <Variant 
                        key={2}
                        campaignId={props.selectedCampaignId}
                        introText={introTextB}
                        destinationUrl={destinationUrlB}
                        headline={headlineB}
                        variant="B"
                        introTextCursorPosition = {introTextBCursorPosition}
                        setIntroTextCursorPosition = {setIntroTextBCursorPosition}
                        headlineCursorPosition = {headlineBCursorPosition}
                        setHeadlineCursorPosition = {setHeadlineBCursorPosition}
                        destinationUrlCursorPosition = {destinationUrlBCursorPosition}
                        setDestinationUrlCursorPosition = {setDestinationUrlBCursorPosition}
                        handleChangeIntroText={handleChangeIntroTextB}
                        handleChangeHeadline={handleChangeHeadlineB}
                        handleChangeDestinationUrl={handleChangeDestinationUrlB}
                        handleSave={handleSave}
                        introTextCheckMarkState={introTextBCheckMarkState}
                        headlineCheckMarkState={headlineBCheckMarkState}
                        destinationUrlCheckMarkState={destinationUrlBCheckMarkState}
                        toggleCheckMark={toggleCheckMark}
                        introTextCount={introTextBCount}
                        headlineCount={headlineBCount}
                        destinationUrlCount={destinationUrlBCount}
                    />
                    
                    <div style={{
                        position: "absolute", 
                        left: "350px", 
                        top: "10px", 
                        height: '440px', 
                        width: "105vh"
                        }} >
                        <h4>Ad Preview</h4>
                        <div 
                            style={{
                                border: "solid 1px  #DDDDDD", 
                                background: "#F9F9F9",
                            }}
                            >
                            <div style={{height: "5px"}}></div>
                            <div >
                                <div 
                                    style={{
                                            position: "relative", 
                                            left: "10px", 
                                            width: "90%", 
                                            height: "80px"
                                        }}
                                    >
                                    <img alt="logo" src={logo} style={{width: "50px"}} />
                                    <div 
                                        style={{
                                                position: "relative", 
                                                top: "-50px", 
                                                left: "55px"
                                            }}>
                                        <span style={{fontWeight: "bold"}}>Advertaze</span><br/>
                                        <div style={{color: "#999999"}}>38 Followers<br/>
                                        Promoted</div>
                                    </div>
                                    <div 
                                        style={{
                                            paddingTop: "5px",
                                            position: "relative", 
                                            top: "-50px"
                                        }}
                                    >
                                        <input  disabled
                                            style={{
                                                fontWeight: "bold", 
                                                fontFamily: 'Roboto', 
                                                fontSize: "16px", 
                                                backgroundColor: "rgb(249, 249, 249)",
                                                width: "100%"}} 
                                            type="text" 
                                            value={
                                            (selectedCompany !== "") ? introTextB.replaceAll("{COMPANY_NAME}", selectedCompany) : introTextB
                                        } 
                                            onChange={console.log}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                {/* Tab Page: Variant B  -- Includes Ad Preview but NOT Design Editor*/}


                {/* Click to preview an account -- This list is shared between both Tab Pages */}
                <div> 
                    <div id="upload-info" className="alert alert-danger"></div>
                    <div className="label-campaign-form-field " style={{paddingTop:"2px", "paddingBottom": "2px"}}>Click to preview an account:</div>
                    <div className="companies-list"> 
                        {
                            companiesList && companiesList.length > 0 ?
                            companiesList.map((item) => (
                                <div key={item.id} style={{padding:"2px"}}>
                                    <input 
                                        id={item.linkedin_company_id}
                                        type='text' 
                                        key={item.id} 
                                        campaignid={item.campaign_id}
                                        linkedincompanyid={item.linkedin_company_id}
                                        className="editable-company-name px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pr-10" 
                                        defaultValue={item.common_name} 
                                        onMouseLeave={wipeSelectedCompany}
                                        onMouseEnter={() => {
                                            let companyName = document.getElementById(item.linkedin_company_id).value;
                                            setSelectedCompany(companyName)}
                                        }
                                        onChange={handleCompanyNameChange} 
                                        onClick={() => {
                                            let companyName = document.getElementById(item.linkedin_company_id).value;
                                            setSelectedCompany(companyName)}
                                        }
                                        /> 

                                </div>
                            )) : 
                            <div 
                                key={Math.random()} 
                                className="no-campaign-companies-found">Companies</div>
                        }  
                    </div>
                    <br/>
                </div>
                {/* Click to preview an account -- This list is shared between both Tab Pages */}
                
                {/* Action Buttons: Save, Delete, Submit */}
                <div>

                        <ChangeConfirmationModal
                            buttonFormat="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            confirmationDialogTitle='Confirm save'
                            confirmationDialogPrompt='Are you sure you want to save this campaign?'
                            affirmativeButtonText="Save Campaign"
                            negativeButtonText="CONTINUE EDITING"
                            successMessage='Saved Successfully' 
                            failureMessage='Failed to save campaign.'
                            onClick={handleSave}
                            confirmCampaignExistsRequired={false}
                            campaignExists={props.selectedCampaignId < 0 ? false : true}
                            buttonText="Save"
                            duration={2000}
                        />

                        <ChangeConfirmationModal
                            buttonFormat="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            confirmationDialogTitle='Confirm deletion'
                            confirmationDialogPrompt='Are you sure you want to delete this campaign?'
                            affirmativeButtonText="Delete Campaign"
                            negativeButtonText="CONTINUE EDITING"
                            successMessage='Deleted Successfully' 
                            failureMessage='Failed to deleted campaign.'
                            onClick={handleDelete}
                            confirmCampaignExistsRequired={true}
                            campaignExists={props.selectedCampaignId < 0 ? false : true}
                            buttonText="Delete"
                            duration={2000}
                        />
                        <ChangeConfirmationModal
                            buttonFormat="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            confirmationDialogTitle='Confirm submit'
                            confirmationDialogPrompt='Are you sure you want to submit this campaign?'
                            affirmativeButtonText="Submit Campaign"
                            negativeButtonText="CONTINUE EDITING"
                            successMessage='Submitted Successfully' 
                            failureMessage='Failed to submit campaign.'
                            onClick={handleSubmit}
                            confirmCampaignExistsRequired={false}
                            campaignExists={props.selectedCampaignId < 0 ? false : true}
                            buttonText="Submit"
                            duration={2000}
                        />
                        <ImageUploadInProgressModal displayImageUploadPopup={displayImageUploadPopup}/>
                    </div>
                    {/* Action Buttons: Save, Delete, Submit */}

            </div>
            {/* Tab Page Contents */}


            {/* Design Editors for Tabs A and B -- Outside of the tab pages' content because of incompatability between them and the Design Editor.  Absolutely positioned IN the Tab Pages' Ad Preview though. */}
            <div id="DesignEditorPlaceHolderB" style={{height: '40vh', width: "105vh", position: "absolute", top: "125px", left: "350px"}}>
            <input id="selectedTab" type="hidden" value={props.selectedTab} onChange={console.log}/>
                {props.selectedTab === "A" ?
                    <div style={{border: "solid 0px red"}}>
                        <DesignEditor
                            API_KEY={process.env.REACT_APP_POLOTNO_API_KEY}
                            company={selectedCompany}
                            style={{ height: '54vh', width: "105vh"}}
                            onChange={handleEditorChange}                                             
                            json={props.imageJsonA}
                            backgroundImage={props.backgroundImageA}
                            onBackgroundImageRemove={() => {
                                console.log('background image removed');
                                props.setBackgroundImageA('');
                              }}
                        /> 
                        <div style={{border: "1px solid rgb(221, 221, 221)", background: "rgb(249, 249, 249)", width:"100%"}}>
                            <div
                                    style={{
                                        position: "relative",
                                        height: "70px",
                                        bottom: "0",
                                    }}
                                >
                                <div 
                                    style={{
                                        position: "absolute",
                                        bottom: "0",
                                        paddingLeft: "10px",
                                        paddingBottom: "10px"
                                    }}>
                                    <input disabled
                                        style={{
                                                paddingTop: "10px",
                                                fontWeight: "bold", 
                                                fontFamily: 'Roboto', 
                                                fontSize: "16px",
                                                backgroundColor: "rgb(249, 249, 249)", 
                                                width: "580px"}} 
                                        type="text" 
                                        value={
                                                (selectedCompany !== "") ? headlineA.replaceAll("{COMPANY_NAME}", selectedCompany) : headlineA
                                        } 
                                        onChange={console.log}/>
                                    <div style={{color: "#676767"}}>advertaze.com</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                : 
                    <div>
                        <DesignEditor
                            API_KEY={process.env.REACT_APP_POLOTNO_API_KEY}
                            company={selectedCompany}
                            style={{ height: '54vh', width: "105vh"}}
                            onChange={handleEditorChange}                                             
                            json={props.imageJsonB}
                            backgroundImage={props.backgroundImageB}
                            onBackgroundImageRemove={() => {
                                console.log('background image removed');
                                props.setBackgroundImageB('');
                              }}
                        /> 
                        <div style={{border: "1px solid rgb(221, 221, 221)", background: "rgb(249, 249, 249)", width:"100%"}}>
                        <div
                                style={{
                                    position: "relative",
                                    height: "70px",
                                    bottom: "0",
                                }}
                            >
                                <div 
                                    style={{
                                        position: "absolute",
                                        bottom: "0",
                                        paddingLeft: "10px",
                                        paddingBottom: "10px"
                                    }}>
                                    <input disabled
                                        style={{
                                                paddingTop: "10px",
                                                fontWeight: "bold", 
                                                fontFamily: 'Roboto', 
                                                fontSize: "16px",
                                                backgroundColor: "rgb(249, 249, 249)", 
                                                width: "580px"}} 
                                        type="text" 
                                        value={
                                                (selectedCompany !== "") ? headlineB.replaceAll("{COMPANY_NAME}", selectedCompany) : headlineB
                                        } 
                                        onChange={console.log}/>
                                    <div style={{color: "#676767"}}>advertaze.com</div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                } 
            </div>
            {/* Design Editors for Tabs A and B -- Outside of the tab pages' content because of incompatability between them and the Design Editor.  Absolutely positioned IN the Tab Pages' Ad Preview though. */}

            
        </div>
        {/* Tab Pages: Variant A and Variant B*/}

    </div>
    {/* Client Area Boundary */}

</>
  )
}


