import AuthorizedPostRequest from "../_helpers/authorizedPostRequest.js"
import AuthorizedGetRequest from "../_helpers/authorizedGetRequest.js"

class ApiService {
    static async get(endpoint) {
        const url = process.env.REACT_APP_API_DOMAIN + endpoint;
        if (url.includes("streamCampaignCompanyPhoto")) {
            return AuthorizedGetRequest(true).get(url);
        } else {
            return AuthorizedGetRequest(false).get(url);
        }
    }

    static async post(endpoint, jsonData) {
        const url = process.env.REACT_APP_API_DOMAIN + endpoint;
        if (jsonData) {
            var data = this.transformRequest(jsonData);
        }
        return AuthorizedPostRequest().post(url, data);
    }

    static transformRequest(jsonData) {
        let form_data =  new FormData();
        for (let [key, value] of Object.entries(jsonData)) {
            if (key.includes("[]") && value.constructor === Array) {
                value.forEach((item, index) => {
                    form_data.append(key, item);
                })
            } else {
                form_data.append(key, value);
            }
        }

        return form_data;
    }
}

export default ApiService