import './App.css';
import { Routes, Route} from "react-router-dom";
import { useState } from "react";
import Protected from "./Protected";
import CampaignListPage from "./pages/CampaignListPage";
import BuildYourAdPage from "./pages/BuildYourAdPage";
import Login from "./pages/Login";


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [loginError, setLoginError] = useState("");

    function handleLoginError(error){
      setLoginError(error);
    }

    function clearLoginErrors(){
      setLoginError("");
    }
    const handleLogout = (event) => {
      // event.preventDefault();
      sessionStorage.setItem("jwt", "");
      setIsLoggedIn(false);
    }


    return (
              <div>
                      <Routes>
                        <Route 
                          path='/' 
                          element={<Login   
                                      setIsLoggedIn={setIsLoggedIn} 
                                      loginError={loginError} 
                                      clearLoginErrors={clearLoginErrors}
                                      handleLoginError={handleLoginError}
                                    />} 
                        />
                        <Route path='/CampaignListPage' element={
                            <Protected isLoggedIn={isLoggedIn}>
                              <CampaignListPage 
                                handleLogout={handleLogout} 
                                handleLoginError={handleLoginError}
                              />
                            </Protected>
                          }
                        />
                        <Route path='/BuildYourAdPage' element={
                            <Protected isLoggedIn={isLoggedIn}>
                               <BuildYourAdPage 
                                  handleLogout={handleLogout} 
                                  handleLoginError={handleLoginError}
                                />
                            </Protected>
                          }
                        />
                    </Routes>
              </div>
    );
}
export default App;
