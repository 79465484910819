import React from "react";
export default function CsvFileUploadResults(props) {
  
  return (
    <>
      {props.showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Account Upload Results
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={
                      () => {
                              props.toggleShowModal(false);
                            }
                    }
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="my-4 text-slate-500 text-lg leading-relaxed">
                    <h4 style={{fontWeight: "bold", fontSize:"inherit"}}>Results for {props.uploadedCSVFile}:</h4>
                    <br/>
              
                    {props.accountUploadSuccess && props.accountUploadSuccess.length === 0 ? 
                    "None of the companies loaded successfully" 
                    : 
                    "The following " + props.accountUploadSuccess.length + " companies loaded successfully:"}

                    {props.accountUploadSuccess && props.accountUploadSuccess.length === 0 ? 
                    props.toggleProgressIndicatorOff() : null }

                    {props.accountUploadSuccess && props.accountUploadSuccess.length !== 0 ? 
                      <div className="companies-list"> 
                        {props.accountUploadSuccess.map((item,i) => 
                          <div key={item} style={{padding:"2px"}}>
                            <input 
                              type='text' 
                              key={i} 
                              className="editable-company-name px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pr-10" 
                              defaultValue={item} 
                              disabled
                            /> 
                          </div>
                        )}
                      </div>: null }
                    <br/>
                    <br/>
                    {props.accountUploadSkipped && props.accountUploadSkipped.length > 0 ? 
                    "The following " + props.accountUploadSkipped.length + " companies were not loaded because they had previously been loaded:" :  ""}

                    {props.accountUploadSkipped && props.accountUploadSkipped.length > 0 ? 
                        <div className="companies-list"> 
                            {props.accountUploadSkipped.map((item,i) => 
                              <div key={item} style={{padding:"2px"}}>
                                <input 
                                  type='text' 
                                  key={i} 
                                  className="editable-company-name px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pr-10" 
                                  defaultValue={item} 
                                  disabled
                                /> 
                              </div>
                            )}
                      </div>: null }
                    

                  
                    {props.accountUploadSkipped && props.accountUploadSkipped.length > 0 ? <br/> : null}

                    {props.accountUploadFail && props.accountUploadFail.length > 0 ? "The following " + props.accountUploadFail.length + " companies were not loaded because they could not be found on LinkedIn. Please ensure you have removed the 'http://' from the URL and try again:" : ""}
                    <div className="companies-list"> 
                      {props.accountUploadFail.map((item,i) => 
                        <div key={item} style={{padding:"2px"}}>
                          <input 
                            type='text' 
                            key={i} 
                            className="editable-company-name px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pr-10" 
                            defaultValue={item} 
                            disabled
                          /> 
                        </div>
                      )}
                    </div>

                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={
                      () => {
                              props.toggleShowModal(false);
                            }
                    }
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}