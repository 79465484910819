import React, { useState } from "react";
import CampaignMonth from "./CampaignMonth";
import ClientList from "./ClientList";
import "./components.css";
import {useNavigate} from "react-router-dom";
import ApiService from "../Services/ApiService";
import * as MonthFn from '../utilities/Months';
const {months} = require("../utilities/Months");


function Campaigns(props){
    const navigate = useNavigate();
    const [selectedClientId, setSelectedClientId] = useState(-1);
    const [selectedClientName, setSelectedClientName] = useState("");
    const [clientOptions] = useState([{label: "Select a Client...", value: "test", key: "-1"}]);
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [errorMessage]  = useState("");
    
   async function handleClientChange(clientId, clientName){
        await setSelectedClientId(clientId);
        await setSelectedClientName(clientName);
        await setCampaignOptions([]);


        const url = "/api/campaigns?client_id=" + clientId;
        ApiService.get(url)
            .then(response => {
                let data = response.data;
                let campaigns = data.Campaigns;
                if (data.error) {
                    console.log("Your session has expired.")
                    props.handleLoginError("Your session has expired.");
                    navigate('/'); 
                    return;
                }
                
                let campaignOptionsTemp = [];
                for(let i = 0; i < campaigns.length; i++){
                    let year = campaigns[i].start_date.substring(0,4);
                    let monthString = campaigns[i].start_date.substring(5, 7);
                    let monthNumber = parseInt(monthString) - 1;
                    let monthName = months[monthNumber] + " " + year;

                    //This if statement guarantees that we only take the first campaign in every month:
                    let status = campaigns[i].status;
                    campaignOptionsTemp.push(
                        { 
                            id: i,
                            clientId: clientId,
                            clientName: campaigns[i].client_name,
                            campaignId: campaigns[i].campaign_id,
                            campaignName: campaigns[i].campaign_name,
                            month: monthName,
                            status: status,
                            startDate: campaigns[i].start_date, 
                            endDate: campaigns[i].end_date,
                            linkedInCampaignGroupId: campaigns[i].linkedin_campaign_group_id,
                            label: monthName,
                            value: monthName,
                            field: "CL#"
                        }
                    );//push
                }//for
                campaignOptionsTemp.sort(MonthFn.compareStartDate);
                let campaignOptionsTemp2 = [];
                let lastMonthName = "";
                for(var i = 0; i < campaignOptionsTemp.length; i++){
                    if (campaignOptionsTemp[i].month !== lastMonthName){
                        campaignOptionsTemp2.push({ 
                            id: campaignOptionsTemp[i].id,
                            clientId: campaignOptionsTemp[i].clientId,
                            clientName: campaignOptionsTemp[i].clientName,
                            campaignId: campaignOptionsTemp[i].campaignId,
                            campaignName: campaignOptionsTemp[i].campaignName,
                            month: campaignOptionsTemp[i].month,
                            status: campaignOptionsTemp[i].status,
                            startDate: campaignOptionsTemp[i].startDate, 
                            endDate: campaignOptionsTemp[i].endDate,
                            linkedInCampaignGroupId: campaignOptionsTemp[i].linkedInCampaignGroupId,
                            label: campaignOptionsTemp[i].label,
                            value: campaignOptionsTemp[i].value,
                            field: "CL#"
                        });
                        lastMonthName = campaignOptionsTemp[i].month;
                    }//if
                }//for

                let campaignOptionsTemp3 = MonthFn.addFirstTwoMonths(campaignOptionsTemp2, clientId, clientName);
                setCampaignOptions(campaignOptionsTemp3);
            })
            .catch(error => {
                console.log(error);
            })
    }

    function createCampaignBoxes(clientList){
        return campaignOptions.map(function (campaign) {
            let nextTwoMonths = [
                {
                    label: MonthFn.getNextMonth(), 
                    value: MonthFn.getNextMonth()
                },{ 
                    label: MonthFn.getCurrentMonth(), 
                    value: MonthFn.getCurrentMonth()
                }
            ];
            var startDateFormatted = campaign.startDate.substring(5, 7) + 
                            "/" + campaign.startDate.substring(8, 11)  + 
                            "/" + campaign.startDate.substring(0,4);
            var endDateFormatted = campaign.endDate.substring(5, 7) + 
                            "/" + campaign.endDate.substring(8, 11)  + 
                            "/" + campaign.endDate.substring(0,4);
            return (
                <div className="grid-item" key={campaign.campaignId}>
                    <CampaignMonth 
                        clientList={clientList}
                        clientId={campaign.clientId}
                        clientName={campaign.clientName}
                        month={campaign.campaignId < 0 ? 
                            campaign.month + " (Not saved)": 
                            campaign.month 
                        } 
                        status={campaign.status} 
                        flightDates={startDateFormatted + " - " + endDateFormatted} 
                        startDate={campaign.startDate}
                        endDate={campaign.endDate}
                        campaignList={campaignOptions}  
                        nextTwoMonths={nextTwoMonths}
                        campaignId={campaign.campaignId}
                        campaignName={campaign.campaignName}
                        handleClientChange={handleClientChange}
                        handleLoginError={props.handleLoginError}
                    />
                </div>  
            ); 
        });
    }//createCampaignBoxes

    return (
        <div className="page-main">
            <div className="page-content">
                <div className="homepage">

                    <div className="div-campaign-screen-title">Client List</div>
                    <br/><br/><br/>
                    <ClientList
                        selectedClient=""
                        selectedClientName="{SELECT A CLIENT}"
                        selectedClientId={selectedClientId}
                        handleChange={handleClientChange}
                        handleLoginError={props.handleLoginError}
                    />
                    <div>{errorMessage}</div>
                    {selectedClientId === -1 ? <div></div> : 
                        <div className= "grid-container">
                            {createCampaignBoxes(clientOptions)}
                        </div>
                    }
                    
                </div>
            </div>
        </div>
    )
}

export default Campaigns;