// import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
// import { StrictMode } from 'react';
// import App from './App';

// ReactDOM.render(
//   <BrowserRouter><App/></BrowserRouter>,
//   document.getElementById('root')
// );

import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

// 👇️ IMPORTANT: use correct ID of your root element
// this is the ID of the div in your index.html file
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// 👇️ if you use TypeScript, add non-null (!) assertion operator
// const root = createRoot(rootElement!);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);