import React, {useState,useEffect} from "react";
import "../pages/styles.css";
import check from "../images/check-14@2x.png";

export default function Textbox(props) {
  useEffect(() => {
    props.toggleCheckMark(props.id, props.characterCount, props.minCharacters)
 }, [props]); 

 function setCaretPosition(elemId, caretPos) {
    var elem = document.getElementById(elemId);

    if(elem != null) {
        if(elem.createTextRange) {
            var range = elem.createTextRange();
            range.move('character', caretPos);
            range.select();
        }
        else {
            if(elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            }
            else
                elem.focus();
        }
    }
  }

  function debounce(func, timeout = 10){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const moveCursor = debounce((id, start) => {

    setCaretPosition(id, start);
  });

  const handleChange = e => { 
    //Display checkmark or not:
    props.toggleCheckMark(props.id, e.target.value.length,props.minCharacters );

    //Don't allow non-numerics if this is a numeric field:
    if(props.isNumeric){
      document.getElementById(e.target.id).value = e.target.value.replace(/[^\d.-]/g, '');
    }

    //Call handling function:
    if(props.handleChange !== undefined){
        props.handleChange(e.target.value);
    }
    let input = document.getElementById(props.id);
    let start = input.selectionStart;
    // setCaretPosition(props.id, start);
    moveCursor(props.id, start);
  };

  return (
    <div>
      
         <label className="label-campaign-form-field" htmlFor={props.id}>
                { props.insertCompanyNameButtonFlag === true ?  
                  <>
                      <div style={{width: "50%", float: "left", paddingBottom: "10px"}}>
                        {props.label}
                      </div> 
                      <div style={{width: "60%", float: "right", paddingBottom: "10px"}}>
                        {
                          props.insertCompanyNameButtonFlag===true? 
                          <button 
                            className='bp4-button' 
                            style={{height:"5px" }}
                            onClick={
                              e => {
                                      e.preventDefault();
                                      props.handleInsertCompanyName(props.cursorPosition, props.id);
                                  }
                            }
                          >Insert Company
                          </button> : 
                          null
                        }
                      </div>
                  </>
                  : 
                    props.label
                }
                
        </label>
        <div style={{width:'100%'}}>
            <div  
                className="align-middle appearance-none border rounded  text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-500::placeholder" >
                <div style={{width: '92%',  vAlign: 'middle', float: 'left'}}> 
                    <input 
                      id={props.id}
                      style={{border: 'none'}}
                      onBlur={
                          e => {
                            try {
                              if ((props.cursorPosition !== undefined) && (e.target.id !== undefined)){
                                let id = e.target.id;
                                const input = document.getElementById(id);
                                const cursorPosition = input.selectionStart;
                                props.setCursorPosition(cursorPosition);
                              }
                            } catch(error) {
                              console.log("An error occurred:")
                              console.log(error);
                              console.log(e);
                            }
                            
                          }
                      }
                      className="align-middle appearance-none border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-500::placeholder" 
                      type="text"
                      placeholder={props.placeholderText}
                      onChange={
                        e => {
                          handleChange(e)
                        }
                      }
                      maxLength={props.maxCharacters}
                      value={props.value ?? ""}
                    />
                </div>
                <div 
                    className="align-middle" 
                    style={{
                      marginLeft: "90%",
                      position: "relative",
                      top: "-20px",
                      height: "36px"
                    }}
                > 
                  <img 
                    id='check' 
                    src={check} 
                    alt="Check"  
                    className={props.checkMarkState} 
                    style={{position: "absolute", top: "25px"}} />
                </div>
            </div>
            <div className="textbox-character-count">{props.characterCount}/{props.maxCharacters}</div>
        </div>
    </div>

  );
}