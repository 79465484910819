import "./components.css";
import React from "react";
import Dropdown from "./Dropdown";

export function CampaignList(props)  {

    React.useEffect(() => {
        (async function() {
            let x = await props.getCampaignListOptions(props.selectedClientId, props.selectedClientName);
        })();
        
    }, [props.refreshCampaignList]);

    return (
        <div>
            <div  style={{zIndex: 20}}> 

            {props.campaignListOptions === undefined ? null : 
                <Dropdown 
                    id="CampaignList"
                    labelTop=""
                    labelLeft=""
                    width="220px"
                    defaultValue={props.selectedCampaignId}
                    defaultText={props.selectedCampaignName}
                    options={props.campaignListOptions}
                    handleChange={props.handleChange}
                /> }
                
            </div>
        </div>
        );
    }
    
    export default CampaignList;