import React from "react";
import "./components.css";
import EditCampaignButton from "./EditCampaignButton";
import CloneButton from "./CloneButton";

export function CampaignMonth(props)  {
    let action;
    const today = new Date();
    const endDate = Date.parse(props.endDate);
    const endDatePassed = today > endDate;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    // const clientSignUpDayOfMonth = "16";  //Replace this
    // var clientSignUpDay = currentYear + "-" + currentMonth + "-" + clientSignUpDayOfMonth;



    if ((props.status === "ACTIVE")|| (endDatePassed)){
        action = <div>
                    <CloneButton 
                        campaignList={props.campaignList} 
                        nextTwoMonths={props.nextTwoMonths} 
                        monthName={props.month}
                        handleClientChange={props.handleClientChange}
                        handleLoginError={props.handleLoginError}
                    />
                </div>;
    } else if (props.status === "DRAFT"){
        action = <EditCampaignButton
                    clientList={props.clientList}
                    clientId={props.clientId}
                    clientName={props.clientName}
                    campaignId={props.campaignId}
                    month={props.month}
                    campaignName={props.campaignName}
                    campaignList={props.campaignList} 
                    flightDates={props.flightDates}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    buttonText={props.campaignId === -100? "Create Ad" : "Edit Ad"}
                />;
    } else {
        action =<div className="campaign-month-body-section-box"></div>
    }
    
        return (
            <div className="gridItem">
                <div className="campaign-month-box shadow-lg">
                    <div className="campaign-month-header-box">
                        <div className="campaign-month-header-text-box">
                            {props.month} 
                        </div>
                    </div>
                    <div className="campaign-month-body-box">
                        <div className="campaign-month-body-section-box">
                            {/* Campaign #{props.campaignId === -1? 'NA': props.campaignId} */}
                            <div className="campmaign-month-status">
                            { props.status === "ACTIVE" ? "Submitted" : "Not Submitted"}
                            </div>
                        </div>
                        {action}
                        <div className="flight-dates"> {props.flightDates}</div>
                    </div>
                </div>
            </div>
        );
}

export default CampaignMonth;
