import React from "react";

export default function ChangeConfirmationModal(props) {
  const [showChangeConfirmationModal, setShowChangeConfirmationModal] = React.useState(false);
  const [confirmationTitle, setConfirmationTitle] = React.useState(props.confirmationDialogTitle);
  const [confirmationPrompt, setConfirmationPrompt] = React.useState(props.confirmationDialogPrompt);
  const [negativeButtonText, setNegativeButtonText] = React.useState(props.negativeButtonText);
  const [affirmativeButtonVisibility, setAffirmativeButtonVisibility] = React.useState("display: visible");
  const FormWithToasts = () => {

    function resetDialog(){
      setShowChangeConfirmationModal(false);
      setConfirmationTitle(props.confirmationDialogTitle);
      setConfirmationPrompt(props.confirmationDialogPrompt);
      setNegativeButtonText(props.negativeButtonText);
      setAffirmativeButtonVisibility("display: visible");
    }


    function displayResult(status) {
      return new Promise(resolve => {
        if(status==="SUCCESS"){
          setConfirmationTitle("Success");
          setConfirmationPrompt(props.successMessage);
          setAffirmativeButtonVisibility("hidden");
          setNegativeButtonText("Close")
        }else { //FAILURE
          setConfirmationTitle("Failure");
          setConfirmationPrompt(props.failureMessage);
          setAffirmativeButtonVisibility("hidden");
          setNegativeButtonText("Close")
        }

        setTimeout(async function doSomething() {
          resetDialog();
          resolve();
        }, 2000);
      });
    };

    return  <form>
                 <button id="ActionButton"
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 "
                    style={{visibility: affirmativeButtonVisibility}}
                    type="button"
                    onClick={
                      async function(e){
                          e.preventDefault();
                          let result;

                          if(props.confirmCampaignExistsRequired){  //If this is a deletion (i.e., we have to confirm existance of the campaign before we try to delete it)
                               if(props.campaignExists){ 
                                resetDialog();
                                result = await props.onClick(); //Delete the campaign and refresh the page
                              } else {  //Campaign does not exist
                                displayResult("Campaign does not exist.  props.campaignExists: " + props.campaignExists);
                              }//if
                          } else {  //This is not a deletion

                            document.getElementById("ActionButton").style.visibility = 'hidden';
                            document.getElementById("NegativeButton").style.visibility = 'hidden';
                            document.getElementById("ConfirmationPrompt").innerHTML = "<div style='text-align: center'> Action in progress.  Please wait...<br>" + 
                            `<img id="progress-indicator" class="progress-circle" src="/static/media/progress.629706a57954be0da6b9.gif" alt="progress"></img></div>`;

                            result = await props.onClick(); //Try to save or submit
                            displayResult(result);
                          }//if
                      }//annonymous function
                  }//onClick
                  >
                    {props.affirmativeButtonText}
                </button>
            </form>;
}; //function FormWithToasts

  return (
    <>
    {/* CAMPAIGN EXISTS: {props.campaignExists} */}
    {props.campaignExists || props.buttonText !== "Delete" ? <button
        className={props.buttonFormat}
        type="button"
        onClick={() => setShowChangeConfirmationModal(true)}
      >
         {props.buttonText}
      </button> : 
      <button
        className={props.buttonFormat}
        type="button"
        onClick={() => setShowChangeConfirmationModal(true)}
        disabled
      >
         {props.buttonText}
      </button>
    }

      {showChangeConfirmationModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" style={{width: "450px"}}>
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {confirmationTitle}
                  </h3>
                  <button onClick={() => setShowChangeConfirmationModal(false)}>
                    <span>×</span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p id="ConfirmationPrompt" className="my-4 text-slate-500 text-lg leading-relaxed">
                    {confirmationPrompt}
                  </p>
                </div>
                {/*footer*/}
                <div 
                className="items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                style={{textAlign: "center"}} >
                  <FormWithToasts/>
                  <button
                    id="NegativeButton"
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                        setShowChangeConfirmationModal(false);
                        setConfirmationTitle(props.confirmationDialogTitle);
                        setConfirmationPrompt(props.confirmationDialogPrompt);
                        setNegativeButtonText(props.negativeButtonText);
                        setAffirmativeButtonVisibility("display: visible");
                    }
                  }
                  >
                    {negativeButtonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}