import React from "react";
import "./components.css";
import {useNavigate} from "react-router-dom";


export function EditCampaignButton(props)  {
    const navigate = useNavigate();
    function editCampaign() {
        navigate('/BuildYourAdPage', {state: {
            clientId: props.clientId,
            clientName: props.clientName,
            campaignId: props.campaignId,
            month: props.month,
            campaignName: props.campaignName,
            flightDates: props.flightDates,
            startDate: props.startDate,
            endDate: props.endDate
        }}); 
    }
    return (
        <div 
            className="campaign-month-body-section-box">
            <button 
                className="create-campaign-button"
                onClick={() => editCampaign()}
            >
                <div className="create-campaign-button-text">{props.buttonText}</div>
            </button>
        </div>
        );
    }
    
    export default EditCampaignButton;