//https://advertaze-templates.s3.ca-central-1.amazonaws.com/client-accounts-upload-template.csv
import CsvDownloader from 'react-csv-downloader';

function CsvTemplateDownloadLink(){

    const columns = [{
        id: 'A',
        displayName: 'Company Name'
      }, {
        id: 'B',
        displayName: 'Company Domain'
      }, {
        id: 'C',
        displayName: 'LinkedIn URL'
      }, {
        id: 'D',
        displayName: 'Account Owner'
      }
    
    ];


    const datas = [
    //   {
    //   A: 'A',
    //   B: 'B',
    //   C: 'C',
    //   D: 'D'
    // }
  ];
    

      return (
        <div style={{  paddingTop: "5px"}} >
          <CsvDownloader
            className="bp4-button" 
            style={{  
              width: "235px",
              paddingLeft:"10%", 
              paddingRight:"10%", 
              textAlign:"center",
              backgroundColor: "#F6F7F9",
              color: "#74787C"
            }}
              filename="Advertaze_Account_List_template"
              extension=".csv"
              separator=","
              wrapColumnChar=""
              columns={columns}
              datas={datas}
              text="Download Template" >

              <span icon="upload" aria-hidden="true" tabIndex="-1" className="bp4-icon bp4-icon-upload">
                    <svg vdata-icon="download" width="16" height="16" 
                      viewBox="0 0 16 16" >
                      <g id="download_1_">
                        <g>
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.99-0.01c-4.42,0-8,3.58-8,8s3.58,8,8,8s8-3.58,8-8S12.41-0.01,7.99-0.01z
                            M11.7,9.7l-3,3c-0.18,0.18-0.43,0.29-0.71,0.29s-0.53-0.11-0.71-0.29l-3-3C4.1,9.52,3.99,9.27,3.99,8.99c0-0.55,0.45-1,1-1
                            c0.28,0,0.53,0.11,0.71,0.29l1.29,1.29V3.99c0-0.55,0.45-1,1-1s1,0.45,1,1v5.59l1.29-1.29c0.18-0.18,0.43-0.29,0.71-0.29
                            c0.55,0,1,0.45,1,1C11.99,9.27,11.88,9.52,11.7,9.7z"/>
                        </g>
                      </g>
                    </svg>
                </span>
                <span>Download Template</span>
            </CsvDownloader>
        </div>
      );
}

export default CsvTemplateDownloadLink;