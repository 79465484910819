import React from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react-lite';
import { observable, reaction, toJS } from 'mobx';

import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import {
  SidePanel,
  SectionTab,
  TextSection,
  UploadSection,
} from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import createStore from 'polotno/model/store';
import VscPreview from '@meronex/icons/vsc/VscPreview';
import { Button, EditableText } from '@blueprintjs/core';
import styled from 'polotno/utils/styled';

import {
  unstable_useHtmlTextRender,
  unstable_registerNextDomDrop,
  unstable_setTextOverflow,
  unstable_setTextVerticalResizeEnabled,
  addGlobalFont,
} from 'polotno/config';

unstable_useHtmlTextRender(true);
addGlobalFont({ fontFamily: 'Arial' });
addGlobalFont({ fontFamily: 'Helvetica' });
addGlobalFont({ fontFamily: 'Times' });
addGlobalFont({ fontFamily: 'Times New Roman' });
addGlobalFont({ fontFamily: 'Courier' });
addGlobalFont({ fontFamily: 'Tahoma' });
addGlobalFont({ fontFamily: 'Comic Sans MS' });
addGlobalFont({ fontFamily: 'Impact' });
// unstable_setTextVerticalResizeEnabled(true);

const Container = styled('div')`
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;

  .bp3-dark & .polotno-text-preview-plain {
    filter: invert(1);
  }
`;

const DragButton = ({ onSelect, ...props }) => {
  return (
    <Button
      {...props}
      draggable
      className="polotno-close-panel"
      onClick={() => onSelect()}
      onDragStart={() => {
        unstable_registerNextDomDrop(({ x, y }) => {
          onSelect({ x, y });
        });
      }}
      onDragEnd={(e) => {
        unstable_registerNextDomDrop(null);
      }}
    />
  );
};

const PLACEHOLDER = `{COMPANY_NAME}`;

export const TextPanel = observer(({ store }) => {
  React.useEffect(() => {
    store.loadFont('Roboto');
  }, []);

  const addText = (attrs) => {
    const width = attrs.width || store.width / 2;

    const x = attrs?.x || store.width / 6;
    const y = (attrs?.y || store.height / 2) - attrs.fontSize / 2;

    const element = store.activePage?.addElement({
      type: 'text',
      fontFamily: 'Roboto',
      ...attrs,
      x,
      y,
      width: width,
    });
    element?.toggleEditMode(true);
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Container>
        <DragButton
          style={{
            marginBottom: '5px',
            width: '100%',
            fontSize: '14px',
            fontFamily: 'Roboto',
          }}
          onSelect={(pos) => {
            addText({
              ...pos,
              fontSize: 30,
              text: `Add your text and mention the ${PLACEHOLDER}`,
              align: 'left',
              fontFamily: 'Roboto',
              width: 700,
            });
          }}
        >
          {'Add text to the design'}
        </DragButton>
      </Container>
    </div>
  );
});

const companiesList = observable([]);

let lastTime = Date.now();
const throttle = () => {
  // if we change company name too often, we will get little UI lags
  // because layout algorithm is not very fast
  // so when we detect that, we need to wait a little bit
  const timeDiff = Date.now() - lastTime;
  lastTime = Date.now();
  if (timeDiff < 100) {
    return new Promise((resolve) => setTimeout(resolve, 100));
  }
  return;
};

const resetName = async (store) => {
  unstable_setTextOverflow('resize');
  store.activePage.children.forEach((child) => {
    if (child.custom?.oldText) {
      child.set({
        text: child.custom?.oldText,
        height: child.custom?.oldHeight || child.height,
        fontSize: child.custom?.oldFontSize || child.fontSize,
        custom: {
          ...child.custom,
          oldText: null,
          oldHeight: null,
          oldFontSize: null,
        },
      });
    }
  });
};

const replaceName = async (store, name) => {
  unstable_setTextOverflow('change-font-size');
  store.activePage?.children.forEach(async (child) => {
    if (child.type !== 'text') {
      return;
    }
    const oldText = child.custom?.oldText || child.text;
    if (oldText.indexOf(PLACEHOLDER) === -1) {
      return;
    }
    child.set({
      text: oldText.replace(PLACEHOLDER, name),
      fontSize: child.custom?.oldFontSize || child.fontSize,
      custom: {
        oldText,
        oldFontSize: child.custom?.oldFontSize || child.fontSize,
        oldHeight: child.custom?.oldHeight || child.height,
      },
    });
    return;
  });
};

// define the new custom section
const Preview = {
  name: 'preview',
  Tab: (props) => (
    <SectionTab name="Previews" {...props}>
      <VscPreview />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    return (
      <div
        onMouseLeave={() => {
          resetName(store);
        }}
        style={{ overflow: 'auto', height: '100%' }}
      >
        <div>Preview as:</div>
        {companiesList.map((name, index) => {
          return (
            <div
              key={index}
              style={{
                width: '100%',
                marginBottom: '10px',
                textAlign: 'left',
                padding: '10px',
                boxShadow: '0px 0px 2px rgba(0,0,0,0.5)',
                borderRadius: '3px',
                backgroundColor: 'rgba(0,0,0,0.05)',
                cursor: 'pointer',
              }}
              onMouseEnter={() => {
                replaceName(store, name);
              }}
              onMouseLeave={() => {
                resetName(store);
              }}
            >
              <EditableText
                value={name}
                onChange={(value) => {
                  companiesList[index] = value;
                  replaceName(value);
                }}
              ></EditableText>
            </div>
          );
        })}
      </div>
    );
  }),
};

TextSection.Panel = TextPanel;
// add new section

const addStyle = (link) => {
  var ss = document.createElement('link');
  ss.type = 'text/css';
  ss.rel = 'stylesheet';
  ss.href = link;
  document.getElementsByTagName('head')[0].appendChild(ss);
};

addStyle('https://unpkg.com/@blueprintjs/icons@^4/lib/css/blueprint-icons.css');
addStyle('https://unpkg.com/@blueprintjs/core@^4/lib/css/blueprint.css');
addStyle(
  'https://unpkg.com/@blueprintjs/popover2@1/lib/css/blueprint-popover2.css'
);

const ActionControls = ({ store }) => {
  return (
    <div>
      <Button
        minimal
        onClick={() => {
          const element = store.activePage?.addElement({
            type: 'text',
            fontSize: 30,
            text: `Add your text and mention the ${PLACEHOLDER}`,
            align: 'left',
            width: 700,
          });
        }}
      >
        Add Text
      </Button>
      <Button
        onClick={() => {
          store.saveAsImage({ fileName: 'advertaze.png' });
        }}
        minimal
      >
        Download
      </Button>
    </div>
  );
};

const prepareJSON = (json) => {
  json = JSON.parse(JSON.stringify(json));
  json.pages.forEach((page) => {
    page.children.forEach((child) => {
      if (child.custom?.oldText) {
        child.text = child.custom?.oldText || child.text;
        child.height = child.custom?.oldHeight || child.height;
        child.fontSize = child.custom?.oldFontSize || child.fontSize;
        child.custom = null;
      }
    });
  });
  return json;
};

export const DesignEditor = ({
  API_KEY,
  companies,
  company,
  style,
  json,
  onChange,
  onCompaniesListChange,
  onBackgroundImageRemove,
  backgroundImage,
}) => {
  const changeRef = React.useRef(onChange);
  changeRef.current = onChange;

  const onBackRemoveRef = React.useRef(onBackgroundImageRemove);
  onBackRemoveRef.current = onBackgroundImageRemove;

  const skipChangeTrigger = React.useRef(false);

  const store = React.useMemo(() => {
    const s = createStore({ key: API_KEY });
    s.setSize(1200, 628);
    s.addPage();
    if (json) {
      s.loadJSON(json);
    }

    // background image remove triggered
    let backgroundEventTriggered = false;
    s.on('change', () => {
      if (skipChangeTrigger.current) {
        return;
      }
      if (changeRef.current) {
        changeRef.current(prepareJSON(s.toJSON()));
      }
      // search for the background image
      if (!s.activePage) {
        return;
      }

      const background = s.activePage.children.find(
        (el) => el.name === 'background'
      );
      if (!background && onBackRemoveRef.current && !backgroundEventTriggered) {
        onBackRemoveRef.current();
        backgroundEventTriggered = true;
      }
      if (background) {
        backgroundEventTriggered = false;
      }
    });
    return s;
  }, []);

  React.useEffect(() => {
    if (companies) {
      companiesList.replace(companies);
    }
  }, [companies]);

  React.useEffect(() => {
    skipChangeTrigger.current = true;
    const changed =
      JSON.stringify(json) !== JSON.stringify(prepareJSON(store.toJSON()));

    if (!changed) {
      skipChangeTrigger.current = false;
      return;
    }

    if (json) {
      store.loadJSON(json);
    } else {
      store.deletePages(store.pages.map((page) => page.id));
      store.addPage();
    }
    skipChangeTrigger.current = false;
  }, [json]);

  React.useEffect(() => {
    reaction(
      () => toJS(companiesList),
      (newList) => {
        if (onCompaniesListChange) {
          onCompaniesListChange(newList);
        }
      }
    );
  }, []);

  const initialLoad = React.useRef(true);
  React.useEffect(() => {
    if (!store.activePage) {
      return;
    }
    if (initialLoad.current) {
      skipChangeTrigger.current = true;
    }
    initialLoad.current = false;
    let backgroundEl = store.activePage.children.find(
      (el) => el.name === 'background'
    );
    if (!backgroundEl) {
      backgroundEl = store.activePage.addElement({
        type: 'image',
        name: 'background',
        src: backgroundImage,
      });
    }
    backgroundEl.set({
      src: backgroundImage,
      width: store.width,
      height: store.height,
      cropX: 0,
      cropY: 0,
      cropWidth: 1,
      cropHeight: 1,
      visible: !!backgroundImage,
    });
    backgroundEl.moveBottom();
    skipChangeTrigger.current = false;
  }, [backgroundImage]);

  const timeoutRef = React.useRef(null);
  React.useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (!company || company === PLACEHOLDER) {
        resetName(store);
      } else {
        resetName(store);
        replaceName(store, company);
      }
    }, 200);
  }, [company]);

  const sections = [];

  if (companies && companies.length) {
    sections.push(Preview, UploadSection, TextSection);
  }

  return (
    <div style={style || { width: '100vw', height: '100vh' }}>
      <PolotnoContainer style={{ width: '100%', height: '100%' }}>
        {!!sections.length && (
          <SidePanelWrap>
            <SidePanel
              store={store}
              sections={sections}
              defaultSection="upload"
            />
          </SidePanelWrap>
        )}
        <WorkspaceWrap>
          <Toolbar
            store={store}
            components={{
              ActionControls,
            }}
          />
          <Workspace store={store} components={{ PageControls: () => null }} />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </div>
  );
};
