import "./components.css";
import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import ApiService from "../Services/ApiService";
import * as UtilityFn from '../utilities/Utilities';
import {useNavigate} from "react-router-dom";

export function ClientList(props)  {
    const navigate = useNavigate();
    const [clientOptions, setClientOptions] = useState([{label: "Select a Client...", value: "test", key: "-1"}]);
    const [selectedClientId, setSelectedClientId] = useState(props.selectedClientId);
    const [selectedClientName, setSelectedClientName] = useState(props.selectedClientName);

    useEffect(() => {
        let clientListTemp = [];
        const url = '/api/clients';

        ApiService.post(url)
        .then(async function (response) {
            let data = response.data.Clients;
            for(let i = 0; i < data.length; i++){
                clientListTemp.push(
                    { 
                        label: data[i].client_name, 
                        value: data[i].client_id, 
                        id: data[i].client_id,
                        field: "CL#"
                    });
            }
            clientListTemp.sort( UtilityFn.compare );
            setClientOptions(clientListTemp);
        })
        .catch(function (error) {
            console.log("Your session expired.");
            props.handleLoginError("Your session expired.");
            navigate('/'); 
        })
       
    }, []);

    return (
        <div>
            <div  style={{zIndex: 20}}> 
                <Dropdown 
                    id="ClientList"
                    labelTop=""
                    labelLeft="Client"
                    width="275px"
                    defaultValue={props.selectedClientId}
                    defaultText={props.selectedClientName} //selectedClientName}
                    options={clientOptions}
                    handleChange={props.handleChange}
                />
            </div>
        </div>
        );
    }
    
    export default ClientList;