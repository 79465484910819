import axios from "axios";

const AuthorizedPostRequest = () => {
    let defaultOptions = {
        baseURL: "",
        method: 'post',
        crossDomain: true,
        headers: {
            'content-type': 'multipart/form-data'
        },
    };
  
    // Create instance
    let instance = axios.create(defaultOptions);
  
    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
      const token = sessionStorage.getItem('jwt');
      config.headers.Authorization =  token ? `Bearer ${token}` : '';
      return config;
    });
  
    return instance;
  };

  export default AuthorizedPostRequest