import React, {useEffect} from "react";
import "./components.css";
export function DragAndDropFileUpload(props)  {
  const [fileToUpload, setFileToUpload] = React.useState(props.dragAndDropMessage);
  const [promptColor, setPromptColor] = React.useState("text-inherit")

  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
      setTimeout(revertFileUploadArea, 2500);
    }
  };
  
  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
      setTimeout(revertFileUploadArea, 2500);
    }
  };
  
// triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  function revertFileUploadArea(){
    setPromptColor("text-inherit");
    setFileToUpload(props.dragAndDropMessage)
  }
 
  function handleFiles(files){
    let isPermissibleFileType = false;
    for(var i = 0; i < props.permissibleFileTypes.length; i++){
        if(files[0].name.endsWith(props.permissibleFileTypes[i])) {
            isPermissibleFileType = true;
        }//if
    }//for

    if(isPermissibleFileType){
        let fileName = files[0].name;
        if (fileName.length > 35){
          fileName = files[0].name.substring(0, 35) + "...";
        }
        setFileToUpload(fileName)
        props.toggleProgressIndicator();
        props.onChange(files[0]);
    } else {
        setFileToUpload("Sorry, bad file type.")
        setPromptColor("text-red-600");
        setTimeout(revertFileUploadArea, 2500);
    }//if
  }
  
  return (
    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input style={{visibility:"hidden"}}
        ref={inputRef} 
        type="file" 
        id={props.id}
        multiple={true} 
        onChange={handleChange} 
      />
      <label id="label-file-upload" htmlFor={props.id} style={{width: props.width, position: "relative", top: "-30px"}} className={dragActive ? "drag-active" : "" }>
        <div>
          <div className={promptColor} style={{color: {promptColor}}}>{fileToUpload}</div>
        </div> 
       
      </label>
      { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </form>

  );    
}
    
    export default DragAndDropFileUpload;