import * as UtilityFn from './Utilities';

export let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function getNextMonth(){
    var d = new Date();
    var monthNumber = d.getMonth();

    if ( monthNumber === 11) {
        return "January " + d.getFullYear() + 1;
    } else {
        return months[monthNumber + 1] + " " + d.getFullYear();
    }
}

export function getCurrentMonth(){
    var d = new Date();
    var monthNumber = d.getMonth();
    return months[monthNumber ] + " " + d.getFullYear();
}

export function compareStartDate( a, b ) {
    if ( a.startDate > b.startDate ){
      return -1;
    }
    if ( a.startDate < b.startDate ){
      return 1;
    }
    return 0;
}

export function compareDate( a, b ) {
    if ( a > b ){
      return -1;
    }
    if ( a < b ){
      return 1;
    }
    return 0;
}

export function monthExists(month, monthList){
    for(let i = 0; i < monthList.length; i++){
        if(monthList[i].month === month){
            return true;
        }
    }//if
    return false;
}

export function getFlightDates(month){
    let year = month.substring(month.length - 4);
    let monthName = month.substring(0, month.indexOf(' ')); 
    let monthNumber = UtilityFn.searchStringInArray(monthName, months) + 1;
    let startDate = year + "-" + String(monthNumber).padStart(2, '0') + "-01";
    let endDate = year + "-" + String(monthNumber).padStart(2, '0') + "-28";
    return {
        startDate: startDate,
        endDate: endDate
    }
}

function getCurrentMonthCampaign(clientId, clientName){
    let flightDates = getFlightDates(getCurrentMonth()); 
    let startDate = flightDates.startDate;
    let endDate = flightDates.endDate;
    let currentMonth = getCurrentMonth();
    return { 
        campaignId: -100,
        campaignName: clientName + "__" + currentMonth,
        clientId: clientId,
        clientName: clientName,
        endDate: endDate,
        field: "Not Saved",
        label: currentMonth,
        linkedInCampaignGroupId: null,
        month: currentMonth,
        startDate: startDate,
        status: "DRAFT",
        value: "-100",
        id: -100

            // clientId: clientId,
            // clientName: clientName,
            // campaignId: -100,
            // campaignName: clientName + "__" + currentMonth,
            // month: currentMonth,
            // status: "DRAFT",
            // startDate: startDate, 
            // endDate: endDate,
            // linkedInCampaignGroupId: null,
            // label: currentMonth,
            // value: "-100",
            // field: "Not Saved",
            // id: -100
        };
}

function getNextMonthCampaign(clientId, clientName){
    let flightDates = getFlightDates(getNextMonth()); 
    let startDate = flightDates.startDate;
    let endDate = flightDates.endDate;
    let nextMonth = getNextMonth();

    return  { 
        campaignId: -101,
        campaignName: clientName + "__" + nextMonth,
        clientId: clientId,
        clientName: clientName,
        endDate: endDate,
        field: "Not Saved",
        label: nextMonth,
        linkedInCampaignGroupId: null,
        month: nextMonth,
        startDate: startDate,
        status: "DRAFT",
        value: "-200",
        id: -100
    };
}

export function addFirstTwoMonths(campaignListTemp, clientId, clientName){

    let currentMonth = getCurrentMonth();
    let nextMonth = getNextMonth();
    let noCurrentMonth = !monthExists(currentMonth, campaignListTemp);
    let noNextMonth = !monthExists(nextMonth, campaignListTemp);

    if(noCurrentMonth && noNextMonth){
        //Add current month    
        campaignListTemp.unshift(getCurrentMonthCampaign(clientId, clientName));
        //Add next month
        campaignListTemp.unshift(getNextMonthCampaign(clientId, clientName));
    }
    if(!noCurrentMonth && noNextMonth ){
        //Add next month
        campaignListTemp.unshift(getNextMonthCampaign(clientId, clientName));
    }
    if(noCurrentMonth && !noNextMonth){
        //Insert current month
         campaignListTemp.splice(1, 0, getCurrentMonthCampaign(clientId, clientName));
    }
    return campaignListTemp;
}//addFirstTwoMonths