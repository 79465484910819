import React from "react";
import "./components.css";
import CloneModal from './CloneModal';
export function CloneButton(props)  {
    return (
        <div className="campaign-month-body-section-box">
            <CloneModal 
            campaignList={props.campaignList} 
            monthName={props.monthName} 
            nextTwoMonths={props.nextTwoMonths}
            handleClientChange={props.handleClientChange}
            handleLoginError={props.handleLoginError}
            />
        </div>
        );
    }
    
    export default CloneButton;