import LeftNavigationBar from "../components/LeftNavigationBar";
import "react-pro-sidebar/dist/css/styles.css";
import Campaigns from "../components/Campaigns";
import "./styles.css";
const CampaignList = (props) => {
    return <div >
                <div className="left" style={{height: "2000px"}}> 
                    <LeftNavigationBar handleLogout={()=>props.handleLogout()}/>
                </div>
                <div className="right">
                    <Campaigns handleLoginError={props.handleLoginError}/>
                </div>
            </div>
   };
   export default CampaignList;