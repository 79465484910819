import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from "react-pro-sidebar";
import {
  Link
} from "react-router-dom";
import {
  FaTachometerAlt,
  FaSignOutAlt
} from "react-icons/fa";
import logo from "../images/Logo_White@small.png";
const environment = process.env.REACT_APP_ENVIRONMENT;
const LeftNavigationBar = ( props ) => {
  
  return (
    <ProSidebar
      image={false}
      breakPoint="md"
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textAlign: "center"
          }}
        >
          <img src={logo} alt="Advertaze Logo"/>
          Client Portal<br/>
          <span style={{color:"red"}}>
              {
                environment === "" || environment.toUpperCase() === "PRODUCTION"? "" : environment
              }
          </span>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            icon={<FaTachometerAlt />}
          >
            <Link to="/CampaignListPage">Dashboard</Link>
          </MenuItem>
        </Menu>
          <Menu iconShape="circle">
            <button onClick={props.handleLogout}>
              <MenuItem
                icon={<FaSignOutAlt />}
              >
                Logout
              </MenuItem>
            </button>
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};

export default LeftNavigationBar;
