import React from 'react';
import Textbox from './Textbox';
import './components.css';

function dateWithMonthsDelay (months) {  
    const date = new Date()
    date.setMonth(date.getMonth() + months)
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ];

    var month = monthNames[date.getMonth()];
    var year = date.getFullYear();
    return month + " " + year;
  }

function Variant(props) {
    const [introText, setIntroText] = React.useState("");
    const [headline, setHeadline] = React.useState("");
    const [destinationUrl, setDestinationUrl] = React.useState("");

    function handleInsertCompanyName(cursorPosition, id){
        if((id === "introTextA") || (id === "introTextB")){

            let first = introText.slice(0, cursorPosition);
            let last = introText.slice(cursorPosition, introText.length );
            let replacementText = first + "{COMPANY_NAME}" + last;
            setIntroText(replacementText); 
            props.handleChangeIntroText(replacementText); 
        }
        
        if((id === "headlineA") || (id === "headlineB")){
            let first = headline.slice(0, cursorPosition);
            let last = headline.slice(cursorPosition, headline.length );
            let replacementText = first + "{COMPANY_NAME}" + last;
            setHeadline(replacementText); 
            props.handleChangeHeadline(replacementText); 
        }

        if((id === "destinationUrlA") || (id === "destinationUrlB")){
            let first = destinationUrl.slice(0, cursorPosition);
            let last = destinationUrl.slice(cursorPosition, destinationUrl.length );
            let replacementText = first + "{COMPANY_NAME}" + last;
            setDestinationUrl(replacementText); 
            props.handleChangeDestinationUrl(replacementText); 
        }
    }//handleInsertCompanyName

    React.useEffect(() => {
        setIntroText(props.introText);
    }, [props.introText]);

    
    React.useEffect(() => {
        setDestinationUrl(props.destinationUrl);
    }, [props.destinationUrl]);

    React.useEffect(() => {
        setHeadline(props.headline);
    }, [props.headline]);

    let dateList = [dateWithMonthsDelay(0), dateWithMonthsDelay(1), dateWithMonthsDelay(2),dateWithMonthsDelay(3),
        dateWithMonthsDelay(4), dateWithMonthsDelay(5), dateWithMonthsDelay(6), dateWithMonthsDelay(7),
        dateWithMonthsDelay(8), dateWithMonthsDelay(9), dateWithMonthsDelay(10), dateWithMonthsDelay(11)];

  return (
      <div id={props.variant} className={props.visibility}>
            <div className="w-full max-w-xs">
                <form className="bg-white">
                    <Textbox 
                        key={100}
                        id={'introText'+props.variant}  
                        label={'Introductory text'} 
                        placeholderText={'Introductory Text'} 
                        maxCharacters={255} 
                        minCharacters={3} 
                        handleChange={props.handleChangeIntroText}
                        insertCompanyNameButtonFlag={true}
                        handleInsertCompanyName={handleInsertCompanyName}
                        cursorPosition = {props.introTextCursorPosition}
                        setCursorPosition  = {props.setIntroTextCursorPosition}
                        value={introText}
                        checkMarkState={props.introTextCheckMarkState}
                        toggleCheckMark={props.toggleCheckMark}
                        characterCount={props.introTextCount}
                    />
                    <br/>
                    <Textbox 
                        key={102}
                        id={'headline'+props.variant}  
                        label={'Headline'} 
                        placeholderText={'Headline'} 
                        maxCharacters={255} 
                        minCharacters={3} 
                        handleChange={props.handleChangeHeadline}
                        insertCompanyNameButtonFlag={true}
                        handleInsertCompanyName={handleInsertCompanyName}
                        cursorPosition  = {props.headlineCursorPosition}
                        setCursorPosition  = {props.setHeadlineCursorPosition}
                        value={headline}
                        checkMarkState={props.headlineCheckMarkState}
                        toggleCheckMark={props.toggleCheckMark}
                        characterCount={props.headlineCount}
                    />
                    <Textbox 
                        key={103}
                        id={'destinationUrl'+props.variant}  
                        label={'Destination URL'} 
                        placeholderText={'Destination URL'} 
                        maxCharacters={255} 
                        minCharacters={3} 
                        handleChange={props.handleChangeDestinationUrl}
                        insertCompanyNameButtonFlag={true}
                        handleInsertCompanyName={handleInsertCompanyName}
                        cursorPosition = {props.destinationUrlCursorPosition}
                        setCursorPosition  = {props.setDestinationUrlCursorPosition}
                        value={destinationUrl}
                        checkMarkState={props.destinationUrlCheckMarkState}
                        toggleCheckMark={props.toggleCheckMark}
                        characterCount={props.destinationUrlCount}
                    />
                             
                </form>
                </div>
      </div> 
    );
}

export default Variant;