import axios from "axios";

const AuthorizedGetRequest = (isImage) => {
    let defaultOptions = {
      baseURL: "",
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        "X-Requested-With": "XMLHttpRequest"
      },
    };

    if (isImage) {
        defaultOptions = {
            baseURL: "",
            method: 'get',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                "X-Requested-With": "XMLHttpRequest"
            },
        };
    }
  
    // Create instance
    let instance = axios.create(defaultOptions);
  
    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
      const token = sessionStorage.getItem('jwt');
      config.headers.Authorization =  token ? `Bearer ${token}` : '';
      return config;
    });
  
    return instance;
  };

  export default AuthorizedGetRequest