import LeftNavigationBar from "../components/LeftNavigationBar";
import "react-pro-sidebar/dist/css/styles.css";
import TabPages from '../components/TabPages';
import { useState, useEffect } from "react";
import {useLocation} from "react-router-dom";
import "./styles.css";
import * as MonthFn from '../utilities/Months';
import ApiService from "../Services/ApiService";
import {useNavigate} from "react-router-dom";

const BuildYourAd = (props) => {
    const {state} = useLocation();
    const [clientId, setClientId] = useState(state.clientId);
    const [clientName, setClientName] = useState(state.clientName);
    const [campaignId, setCampaignId] = useState(state.campaignId);
    const [month, setMonth] = useState(state.month);
    const [campaignName, setCampaignName] = useState(state.campaignName);
    const [flightDates, setFlightDates] = useState(state.flightDates);
    const [startDate, setStartDate] = useState(state.startDate);
    const [endDate, setEndDate] = useState(state.endDate);
    const {months} = require("../utilities/Months");
    const [campaignListOptions, setCampaignListOptions] = useState();
    const [imageJsonA, setImageJsonA] = useState(null);
    const [imageJsonB, setImageJsonB] = useState(null);
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState("A");
    const [backgroundImageA, setBackgroundImageA] = useState("");
    const [backgroundImageB, setBackgroundImageB] = useState("");

    useEffect(() => {
        setClientId(state.clientId);
        setClientName(state.clientName);
        setCampaignId(state.campaignId);
        setMonth(state.month);
        setCampaignName(state.campaignName);
        setFlightDates(state.flightDates);
        setStartDate(state.startDate);
        setEndDate(state.endDate);
    }, []);

    useEffect(() => {
        setClientId(state.clientId);
        setClientName(state.clientName);
        setCampaignId(state.campaignId);
        setMonth(state.month);
        setCampaignName(state.campaignName);
        setFlightDates(state.flightDates);
        setStartDate(state.startDate);
        setEndDate(state.endDate);
    }, [state]);

    function updateSelectedTab(tab){
        setSelectedTab(tab);
    }

    function refreshNewlyCreatedCampaign(lclientId, lclientName, lcampaignId){
        setClientId(lclientId);
        setClientName(lclientName);
        setCampaignId(lcampaignId);
        getCampaignListOptions(lclientId, lclientName);
    }

    function getCampaignListOptions(clientId, clientName){
        const url = '/api/campaigns?client_id='+ encodeURI(clientId);
  
        ApiService.post(url)
        .then(async function (response) {
            let data = response.data.Campaigns;
            let campaignOptionsTemp = [];
            for(let i = 0; i < data.length; i++){
                let year = data[i].start_date.substring(0,4);
                let monthString = data[i].start_date.substring(5, 7);
                let monthNumber = parseInt(monthString) - 1;
                let monthName = months[monthNumber] + " " + year;
                let endDateFormatted = new Date(year, monthNumber, '28'); 
                var today = new Date();
                var dd = 1; 
                var mm = today.getMonth() ; 
                var yyyy = today.getFullYear();
                let firstDayOfThisMonth = new Date(yyyy, mm, dd); 
  
                if ( MonthFn.compareDate( endDateFormatted, firstDayOfThisMonth)===-1){
                    let nextRecord = { 
                        campaignId:  data[i].campaign_id,
                        campaignName:data[i].campaign_name,
                        clientId: clientId,
                        clientName: clientName,
                        endDate:  data[i].end_date, 
                        field: "CL#",
                        flightDates: data[i].start_date + " - " + data[i].end_date,
                        label: monthName,
                        linkedInCampaignGroupId:  data[i].linkedin_campaign_group_id,
                        month: monthName,
                        startDate: data[i].start_date, 
                        status: data[i].campaign_status_id,
                        value: monthName,
                        id:i
                    }
                    campaignOptionsTemp.push(nextRecord);
                    
                }//if
            }//for
            campaignOptionsTemp.sort(MonthFn.compareStartDate);
  
            let lastMonthName = "";
            let campaignOptionsTemp2 = [];
            //Dedupe the list:
            for(let i = 0; i < campaignOptionsTemp.length; i++){
                let monthName = campaignOptionsTemp[i].month;
                let campaignId = campaignOptionsTemp[i].campaignId;
                let endDate = campaignOptionsTemp[i].endDate;
                let startDate = campaignOptionsTemp[i].startDate;
                let flightDates = campaignOptionsTemp[i].flightDates;
                let status = campaignOptionsTemp[i].status;
  
                if (monthName !== lastMonthName){
                    
                        let nextRecord = { 
                            campaignId: campaignId,
                            campaignName:campaignName,
                            clientId: clientId,
                            clientName: clientName,
                            endDate: endDate,
                            field: campaignId,
                            flightDates: flightDates,
                            label: monthName,
                            linkedInCampaignGroupId: null,
                            month: monthName,
                            startDate: startDate,
                            status: status === 2 ? "SUBMITTED" : "DRAFT",
                            value: campaignId,
                            id: campaignId
                        }
                        campaignOptionsTemp2.push(nextRecord);
                    lastMonthName = monthName;
                }
            }//for
  
            let campaignOptionsTemp3 = MonthFn.addFirstTwoMonths(campaignOptionsTemp2, clientId, clientName);
            setCampaignListOptions(campaignOptionsTemp3);
            return campaignOptionsTemp3;
        })
        .catch(function (error) {
            console.log(error);
        })
      }

      //When a user changes a value in the client drop down on the BuildYourAdPage...
    async function handleClientChange(clientId, clientName){
        await setClientId(clientId);
        await setClientName(clientName);
        await setCampaignName(campaignName);
        setCampaignId(-1);
        getCampaignListOptions(clientId, clientName);
        navigate('/BuildYourAdPage', {state: {
          clientId: clientId,
          clientName: clientName,
          campaignId: -1,
          month: month,
          campaignName: campaignName,
          flightDates: flightDates,
          startDate: startDate,
          endDate: endDate
        }});

      }
      
  
      async function handleCampaignChange( campaignId, campaignName, startDate, endDate, flightDates, clientId, clientName){
        let index = campaignName.lastIndexOf(" ");
        campaignName = campaignName.substring(0, index);
        let month = campaignName.substring(0, index);;
        await setCampaignId(campaignId);
        await setStartDate(startDate);
        await setEndDate(endDate);
        await setFlightDates(flightDates);
        await setClientId(clientId);
        await setClientName(clientName);
        
        let state ={
          clientId: clientId,
          clientName: clientName,
          campaignId: campaignId,
          month: month,
          campaignName:campaignName,
          flightDates: flightDates,
          startDate: startDate,
          endDate: endDate
        };
  
        navigate('/BuildYourAdPage', {state: state}); 
      }
    
      function updateSelectedCampaignId(lcampaignId){
           
            setCampaignId(lcampaignId);
      }

    return <div>
                <div className="left" style={{height: "935px"}}> 
                    <LeftNavigationBar handleLogout={()=>props.handleLogout()}/>
                </div>
                <div className="right"> 
                    <TabPages 
                        imageJsonA={imageJsonA}
                        imageJsonB={imageJsonB}
                        setImageJsonA={setImageJsonA}
                        setImageJsonB={setImageJsonB}
                        backgroundImageA={backgroundImageA}
                        backgroundImageB={backgroundImageB}
                        setBackgroundImageA={setBackgroundImageA}
                        setBackgroundImageB={setBackgroundImageB}
                        selectedTab={selectedTab}
                        setSelectedTab={updateSelectedTab}
                        getCampaignListOptions={getCampaignListOptions}
                        campaignListOptions={campaignListOptions}
                        selectedClientId={clientId} 
                        selectedClientName={clientName}
                        selectedCampaignId={campaignId}
                        updateSelectedCampaignId={updateSelectedCampaignId}
                        updateCampaignListOptions={getCampaignListOptions}
                        selectedCampaignName={campaignName}
                        selectedMonth={month}
                        flightDates={flightDates}
                        startDate={startDate}
                        endDate={endDate}
                        refreshNewlyCreatedCampaign={refreshNewlyCreatedCampaign}
                        handleClientChange={handleClientChange}
                        handleCampaignChange={handleCampaignChange}
                        handleLoginError={props.handleLoginError}
                    />
                </div>
            </div>
   };
   export default BuildYourAd;